@import url('https://fonts.googleapis.com/css?family=Roboto:100,100i,300,300i,400,400i,500,500i,700,700i,900,900i&subset=cyrillic-ext');
@import url('https://fonts.googleapis.com/css?family=PT+Sans:400,400i,700,700i&subset=cyrillic-ext');
@font-face {
    font-family: 'Swiss721BlackCondensedBT';
    src: url('../fonts/Swiss721BlackCondensedBT.eot');
    src: url('../fonts/Swiss721BlackCondensedBT.eot') format('embedded-opentype'),
    url('../fonts/Swiss721BlackCondensedBT.woff2') format('woff2'),
    url('../fonts/Swiss721BlackCondensedBT.woff') format('woff'),
    url('../fonts/Swiss721BlackCondensedBT.ttf') format('truetype'),
    url('../fonts/Swiss721BlackCondensedBT.svg#Swiss721BlackCondensedBT') format('svg');
}

aside, nav, footer, header, section, article { display: block }
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
}
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
    display: block;
}
body {
    line-height: 1;
}
ol, ul {
    list-style: none;
}
blockquote, q {
    quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
    content: '';
    content: none;
}
table {
    border-collapse: collapse;
    border-spacing: 0;
}
textarea{
    resize:none;
}
input, textarea { outline:none; }
input:active, textarea:active { outline:none; }
:focus { outline:none; }
body {
    margin:0;
    color:#000;
    font-family: 'Roboto', sans-serif;
    font-size:13px;
    background:#fff;
    min-width:320px;
    height:100%;
    position:relative;
    -webkit-text-size-adjust: none;
}
*{
    box-sizing:border-box;
    -webkit-box-sizing:border-box;
    -moz-box-sizing:border-box;
    -o-box-sizing:border-box;
    -ms-box-sizing:border-box;
}
@mixin after() {
    content:'';
    display:block;
    clear:both;
}
@mixin transition() {
    -webkit-transition: 0.4s;
    -moz-transition: 0.4s;
    -ms-transition: 0.4s;
    -o-transition: 0.4s;
    transition: 0.4s;
}
.container{
    max-width: 1140px;
    margin: 0 auto;
    padding: 0 15px;
}
.header{
    position: relative;
    .top{
        background-color: #45332c;
        -webkit-box-shadow: 0 3px 5px rgba(0, 0, 0, 0.4);
        -moz-box-shadow: 0 3px 5px rgba(0, 0, 0, 0.4);
        box-shadow: 0 3px 5px rgba(0, 0, 0, 0.4);
        position: relative;
        .container{
            height:55px;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            align-items: center;
            .buttons{
                display: flex;
                flex-wrap: wrap;
                .btn-login{
                    cursor: pointer;
                    margin-right: 15px;
                    -webkit-border-radius: 30px;
                    -moz-border-radius: 30px;
                    border-radius: 30px;
                    font-weight: bold;
                    font-size: 11px;
                    color: #fff;
                    text-transform: uppercase;
                    text-align: center;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    background-color: #b43709;
                    -webkit-box-shadow: 0 2px 2px rgba(0,0,0, 0.4);
                    -moz-box-shadow: 0 2px 2px rgba(0,0,0, 0.4);
                    box-shadow: 0 2px 2px rgba(0,0,0, 0.4);
                    width: 97px;
                    height:31px;
                    @include transition;
                    &:hover{
                        @include transition;
                        background-color: #c34618;
                    }
                }
                .btn-registration{
                    cursor: pointer;
                    -webkit-border-radius: 30px;
                    -moz-border-radius: 30px;
                    height:31px;
                    width: 147px;
                    border-radius: 30px;
                    font-weight: bold;
                    font-size: 11px;
                    color: #fff;
                    text-transform: uppercase;
                    text-align: center;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    background-color: #53b412;
                    -webkit-box-shadow: 0 2px 2px rgba(0,0,0, 0.4);
                    -moz-box-shadow: 0 2px 2px rgba(0,0,0, 0.4);
                    box-shadow: 0 2px 2px rgba(0,0,0, 0.4);
                    @include transition;
                    &:hover{
                        @include transition;
                        background-color: #5bc017;
                    }
                }
            }
        }
        position: relative;
        z-index:2;
    }
    .bottom{
        background-color: #f1e8e5;
        -webkit-box-shadow: 0 3px 5px rgba(0, 0, 0, 0.4);
        -moz-box-shadow: 0 3px 5px rgba(0, 0, 0, 0.4);
        box-shadow: 0 3px 5px rgba(0, 0, 0, 0.4);
        .container{
            min-height: 103px;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            align-items: center;
            .logo{
                display: block;
                background: url(../img/logo.png) no-repeat;
                width: 270px;
                height:83px;
                -webkit-background-size:100% 100%;
                background-size:100% 100%;
                a{
                    display: block;
                    height:100%;
                }
            }
            .tel{
                display: flex;
                flex-direction: column;
                background: url(../img/icon3.png) no-repeat 0 0;
                padding-left: 26px;
                justify-content: center;
                align-items: center;
                strong{
                    display: block;
                    font-weight: bold;
                    font-size: 22px;
                    color: #b43709;
                    font-family: 'Swiss721BlackCondensedBT';
                    margin-bottom: 6px;
                }
                a{
                    display: block;
                    text-decoration: none;
                    font-size: 12px;
                    color: #352a26;
                    border-bottom: 1px dashed;
                    padding-bottom: 4px;
                    @include transition;
                }
            }
        }
    }
}
.cities{
    position: relative;
    &:after{
        @include after;
        position: absolute;
        width: 100%;
        height:100px;
        top:-100px;
        background-color: #45332c;
        z-index:3;
    }
    .cities-btn{
        color: #ffffff;
        font-size: 12px;
        height:35px;
        width: 255px;
        border: 1px solid #fff;
        cursor: pointer;
        display: flex;
        align-items: center;
        padding-left: 38px;
        background: url(../img/icon1.png) no-repeat 12px 50% #45332c;
        position: relative;
        z-index:2;
        &:after{
            @include after;
            position: absolute;
            top:50%;
            right: 15px;
            background: url(../img/icon2.png) no-repeat;
            width: 9px;
            height:5px;
            margin-top: -2.5px;
        }
    }
    .cities-dop{
        position: absolute;
        top:-335px;
        border: 1px solid #fff;
        border-top: none;
        width: 100%;
        background-color: #45332c;
        @include transition;
        a{
            padding-left: 38px;
            height:35px;
            display: block;
            text-decoration: none;
            color: #ffffff;
            font-size: 12px;
            line-height: 35px;
            border-bottom: 1px solid #fff;
            @include transition;
            &:last-child{
                border: none;
            }
            &:hover{
                padding-left:45px;
                @include transition;
            }
        }
    }
    .cities-dop2{
        top:34px;
        @include transition;
    }
}
.banner{
    background: url(../img/img.jpg) no-repeat 50% 0;
    min-height: 627px;
    .container{
        padding-top: 320px;
        .search-master{
            max-width: 920px;
            margin: 0 auto;
            -webkit-border-radius: 5px;
            -moz-border-radius: 5px;
            border-radius: 5px;
            background-color: #f1e8e5;
            padding:30px 45px 27px 45px;
            span{
                display: block;
                padding-left: 20px;
                margin-bottom: 20px;
                font-weight: 500;
                font-size: 20px;
                color: #352a26;
                b{
                    text-transform: uppercase;
                    color: #b43709;
                }
            }
            em{
                display: block;
                padding-left: 20px;
                font-style: italic;
                font-size: 14px;
                color: #8a807c;
                margin-bottom: 13px;
            }
            .buttons{
                display: flex;
                flex-wrap: wrap;
                justify-content: center;
                a{
                    cursor: pointer;
                    margin: 0 10px;
                    -webkit-border-radius: 30px;
                    -moz-border-radius: 30px;
                    border-radius: 30px;
                    font-weight: bold;
                    font-size: 19px;
                    color: #fff;
                    text-transform: uppercase;
                    text-align: center;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    background-color: #b43709;
                    -webkit-box-shadow: 0 2px 2px rgba(0,0,0, 0.4);
                    -moz-box-shadow: 0 2px 2px rgba(0,0,0, 0.4);
                    box-shadow: 0 2px 2px rgba(0,0,0, 0.4);
                    width: 180px;
                    height:52px;
                    text-decoration: none;
                    @include transition;
                    &:hover{
                        @include transition;
                        background-color: #c34618;
                    }
                }
                a:last-child{
                    background-color: #53b412;
                    &:hover{
                        @include transition;
                        background-color: #5bc017;
                    }
                }
            }
        }
    }
}
.btn1{
     cursor: pointer;
     margin: 0 10px;
     -webkit-border-radius: 30px;
     -moz-border-radius: 30px;
     border-radius: 30px;
     font-weight: bold;
     font-size: 19px;
     color: #fff;
     text-transform: uppercase;
     text-align: center;
     display: flex;
     align-items: center;
     justify-content: center;
     background-color: #b43709;
     -webkit-box-shadow: 0 2px 2px rgba(0,0,0, 0.4);
     -moz-box-shadow: 0 2px 2px rgba(0,0,0, 0.4);
     box-shadow: 0 2px 2px rgba(0,0,0, 0.4);
     width: 180px;
     height:52px;
     text-decoration: none;
     @include transition;
     &:hover{
         @include transition;
         background-color: #c34618;
     }
 }
.btn2{
    cursor: pointer;
    margin: 0 10px;
    -webkit-border-radius: 30px;
    -moz-border-radius: 30px;
    border-radius: 30px;
    font-weight: bold;
    font-size: 19px;
    color: #fff;
    text-transform: uppercase;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    -webkit-box-shadow: 0 2px 2px rgba(0,0,0, 0.4);
    -moz-box-shadow: 0 2px 2px rgba(0,0,0, 0.4);
    box-shadow: 0 2px 2px rgba(0,0,0, 0.4);
    width: 180px;
    height:52px;
    text-decoration: none;
    @include transition;
    background-color: #53b412;
    &:hover{
        @include transition;
        background-color: #5bc017;
    }
}
.search-master-block{
    overflow: hidden;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
    margin-bottom: 20px;
    form{
        display: flex;
        flex-wrap: nowrap;
        a.rubric{
            display: block;
            color: #fff;
            font-weight: 500;
            min-width: 157px;
            height:60px;
            line-height: 60px;
            background: url(../img/icon4.png) no-repeat 20px 50% #49342D;
            padding-left: 56px;
            text-decoration: none;
            font-size: 20px;
        }
        input[type="text"] {
            display: block;
            font-family: 'Roboto', sans-serif;
            border: none;
            border-top: 1px solid #392C29;
            border-bottom: 1px solid #392C29;
            padding-left: 24px;
            width: 100%;
            font-size: 14px;
            color: #352a26;
            height:60px;
            background-color: #f1e8e5;
        }
        input[type="submit"] {
            cursor: pointer;
            background: url(../img/icon5.png) no-repeat 39px 50% #b43709;
            display: block;
            font-family: 'Roboto', sans-serif;
            border: none;
            padding-left: 78px;
            min-width: 187px;
            font-size: 20px;
            color: #fff;
            height:60px;
            font-weight: 500;
            text-align: left;
            @include transition;
            &:hover{
                @include transition;
                background: url(../img/icon5.png) no-repeat 39px 50% #c34618;
            }
        }
    }
}
.all-services{
    -webkit-box-shadow: 0 3px 5px rgba(0, 0, 0, 0.4);
    -moz-box-shadow: 0 3px 5px rgba(0, 0, 0, 0.4);
    box-shadow: 0 3px 5px rgba(0, 0, 0, 0.4);
    padding-top: 60px;
    .container{
        ul{
            display: flex;
            flex-wrap: wrap;
            li{
                width: 20%;
                margin-bottom: 20px;
                .service-block{
                    width: 160px;
                    display: flex;
                    margin: 0 auto;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    .iconka{
                        margin: 0 auto 15px;
                        background-color: #bfb8b6;
                        -webkit-border-radius: 100px;
                        -moz-border-radius: 100px;
                        border-radius: 100px;
                        border: 3px solid #fff;
                        -webkit-box-shadow: 0 3px 9px rgba(0,0,0, 0.54);
                        -moz-box-shadow: 0 3px 9px rgba(0,0,0, 0.54);
                        box-shadow: 0 3px 9px rgba(0,0,0, 0.54);
                        width: 113px;
                        height:113px;
                        @include transition;
                        b{
                            display: block;
                            width: 100%;
                            height:100%;
                        }
                    }
                    &:hover .iconka{
                        @include transition;
                        background-color: #a6a3a1;
                        border: 3px solid #46342d;
                    }
                    span{
                        display: block;
                        text-align: center;
                        color: #352a26;
                        font-size: 16px;
                        line-height: 20px;
                    }
                }
            }
            li.service1{
                .service-block{
                    .iconka{
                        b{
                            background: url(../img/icon6.png) no-repeat 50% 50%;
                            @include transition;
                        }
                    }
                    &:hover{
                        .iconka{
                            b{
                                background: url(../img/icon6-h.png) no-repeat 50% 50%;
                                @include transition;
                            }
                        }
                    }
                }
            }
            li.service2{
                .service-block{
                    .iconka{
                        b{
                            background: url(../img/icon25.png) no-repeat 62% 50%;
                            @include transition;
                        }
                    }
                    &:hover{
                        .iconka{
                            b{
                                background: url(../img/icon25-h.png) no-repeat 62% 50%;
                                @include transition;
                            }
                        }
                    }
                }
            }
            li.service3{
                .service-block{
                    .iconka{
                        b{
                            background: url(../img/icon7.png) no-repeat 50% 50%;
                            @include transition;
                        }
                    }
                    &:hover{
                        .iconka{
                            b{
                                background: url(../img/icon7-h.png) no-repeat 50% 50%;
                                @include transition;
                            }
                        }
                    }
                }
            }
            li.service4{
                .service-block{
                    .iconka{
                        b{
                            background: url(../img/icon8.png) no-repeat 50% 50%;
                            @include transition;
                        }
                    }
                    &:hover{
                        .iconka{
                            b{
                                background: url(../img/icon8-h.png) no-repeat 50% 50%;
                                @include transition;
                            }
                        }
                    }
                }
            }
            li.service5{
                .service-block{
                    .iconka{
                        b{
                            background: url(../img/icon9.png) no-repeat 50% 50%;
                            @include transition;
                        }
                    }
                    &:hover{
                        .iconka{
                            b{
                                background: url(../img/icon9-h.png) no-repeat 50% 50%;
                                @include transition;
                            }
                        }
                    }
                }
            }
            li.service6{
                .service-block{
                    .iconka{
                        b{
                            background: url(../img/icon10.png) no-repeat 50% 50%;
                            @include transition;
                        }
                    }
                    &:hover{
                        .iconka{
                            b{
                                background: url(../img/icon10-h.png) no-repeat 50% 50%;
                                @include transition;
                            }
                        }
                    }
                }
            }
            li.service7{
                .service-block{
                    .iconka{
                        b{
                            background: url(../img/icon11.png) no-repeat 50% 50%;
                            @include transition;
                        }
                    }
                    &:hover{
                        .iconka{
                            b{
                                background: url(../img/icon11-h.png) no-repeat 50% 50%;
                                @include transition;
                            }
                        }
                    }
                }
            }
            li.service8{
                .service-block{
                    .iconka{
                        b{
                            background: url(../img/icon12.png) no-repeat 50% 50%;
                            @include transition;
                        }
                    }
                    &:hover{
                        .iconka{
                            b{
                                background: url(../img/icon12-h.png) no-repeat 50% 50%;
                                @include transition;
                            }
                        }
                    }
                }
            }
            li.service9{
                .service-block{
                    .iconka{
                        b{
                            background: url(../img/icon13.png) no-repeat 50% 50%;
                            @include transition;
                        }
                    }
                    &:hover{
                        .iconka{
                            b{
                                background: url(../img/icon13-h.png) no-repeat 50% 50%;
                                @include transition;
                            }
                        }
                    }
                }
            }
            li.service10{
                .service-block{
                    .iconka{
                        b{
                            background: url(../img/icon14.png) no-repeat 50% 50%;
                            @include transition;
                        }
                    }
                    &:hover{
                        .iconka{
                            b{
                                background: url(../img/icon14-h.png) no-repeat 50% 50%;
                                @include transition;
                            }
                        }
                    }
                }
            }
            li.service11{
                .service-block{
                    .iconka{
                        b{
                            background: url(../img/icon15.png) no-repeat 50% 50%;
                            @include transition;
                        }
                    }
                    &:hover{
                        .iconka{
                            b{
                                background: url(../img/icon15-h.png) no-repeat 50% 50%;
                                @include transition;
                            }
                        }
                    }
                }
            }
            li.service12{
                .service-block{
                    .iconka{
                        b{
                            background: url(../img/icon16.png) no-repeat 50% 50%;
                            @include transition;
                        }
                    }
                    &:hover{
                        .iconka{
                            b{
                                background: url(../img/icon16-h.png) no-repeat 50% 50%;
                                @include transition;
                            }
                        }
                    }
                }
            }
            li.service13{
                .service-block{
                    .iconka{
                        b{
                            background: url(../img/icon17.png) no-repeat 50% 50%;
                            @include transition;
                        }
                    }
                    &:hover{
                        .iconka{
                            b{
                                background: url(../img/icon17-h.png) no-repeat 50% 50%;
                                @include transition;
                            }
                        }
                    }
                }
            }
            li.service14{
                .service-block{
                    .iconka{
                        b{
                            background: url(../img/icon18.png) no-repeat 50% 50%;
                            @include transition;
                        }
                    }
                    &:hover{
                        .iconka{
                            b{
                                background: url(../img/icon18-h.png) no-repeat 50% 50%;
                                @include transition;
                            }
                        }
                    }
                }
            }
            li.service15{
                .service-block{
                    .iconka{
                        b{
                            background: url(../img/icon19.png) no-repeat 50% 50%;
                            @include transition;
                        }
                    }
                    &:hover{
                        .iconka{
                            b{
                                background: url(../img/icon19-h.png) no-repeat 50% 50%;
                                @include transition;
                            }
                        }
                    }
                }
            }
            li.service16{
                .service-block{
                    .iconka{
                        b{
                            background: url(../img/icon20.png) no-repeat 50% 50%;
                            @include transition;
                        }
                    }
                    &:hover{
                        .iconka{
                            b{
                                background: url(../img/icon20-h.png) no-repeat 50% 50%;
                                @include transition;
                            }
                        }
                    }
                }
            }
            li.service17{
                .service-block{
                    .iconka{
                        b{
                            background: url(../img/icon21.png) no-repeat 50% 50%;
                            @include transition;
                        }
                    }
                    &:hover{
                        .iconka{
                            b{
                                background: url(../img/icon21-h.png) no-repeat 50% 50%;
                                @include transition;
                            }
                        }
                    }
                }
            }
            li.service18{
                .service-block{
                    .iconka{
                        b{
                            background: url(../img/icon22.png) no-repeat 50% 50%;
                            @include transition;
                        }
                    }
                    &:hover{
                        .iconka{
                            b{
                                background: url(../img/icon22-h.png) no-repeat 50% 50%;
                                @include transition;
                            }
                        }
                    }
                }
            }
            li.service19{
                .service-block{
                    .iconka{
                        b{
                            background: url(../img/icon23.png) no-repeat 50% 50%;
                            @include transition;
                        }
                    }
                    &:hover{
                        .iconka{
                            b{
                                background: url(../img/icon23-h.png) no-repeat 50% 50%;
                                @include transition;
                            }
                        }
                    }
                }
            }
            li.service20{
                .service-block{
                    .iconka{
                        b{
                            background: url(../img/icon24.png) no-repeat 50% 50%;
                            @include transition;
                        }
                    }
                    &:hover{
                        .iconka{
                            b{
                                background: url(../img/icon24-h.png) no-repeat 50% 50%;
                                @include transition;
                            }
                        }
                    }
                }
            }
        }
    }
    position: relative;
}
.title{
    display: block;
    text-align: center;
    text-transform: uppercase;
    color: #45332c;
    font-size: 40px;
    font-weight: 900;
    margin-bottom: 75px;
}
.what-help-you{
    background-color: #f1e8e5;
    padding: 32px 0 60px;
    -webkit-box-shadow: 0 3px 5px rgba(0, 0, 0, 0.4);
    -moz-box-shadow: 0 3px 5px rgba(0, 0, 0, 0.4);
    box-shadow: 0 3px 5px rgba(0, 0, 0, 0.4);
    margin-bottom: 70px;
    .title{
        background-color: #45332c;
        min-height: 119px;
        display: flex;
        align-items: center;
        text-align: center;
        font-size: 40px;
        text-transform: uppercase;
        font-weight: 900;
        color: #fff;
        margin-bottom: 20px;
        justify-content: center;
        padding: 0 15px;
    }
    .container{
        .text{
            background-color: #FFFFFF;
            overflow: hidden;
            padding-bottom: 10px;
            img{
                display: block;
                width: 100%;
                height:auto;
                margin-bottom: 40px;
            }
            p{
                display: block;
                padding: 0 40px;
                margin-bottom: 30px;
                color: #352a26;
                font-size: 16px;
                line-height: 22px;
            }
            a.btn3{
                margin: 0 auto 25px;
            }
        }
    }
}
.in-demand{
    margin-bottom: 75px;
}
.slide-block{
    margin: 0 auto;
    text-align: center;
    padding-bottom: 5px;
    img{
        display: block;
        margin: 0 auto 20px;
        -webkit-border-radius: 130px;
        -moz-border-radius: 130px;
        border-radius: 130px;
        -webkit-box-shadow: 0 5px 12px rgba(0, 0, 0, 0.5);
        -moz-box-shadow: 0 5px 12px rgba(0, 0, 0, 0.5);
        box-shadow: 0 5px 12px rgba(0, 0, 0, 0.5);
    }
    span{
        display: block;
        text-align: center;
        color: #352a26;
        font-size: 16px;
        margin-bottom: 8px;
    }
    strong{
        display: block;
        text-align: center;
        font-weight: 900;
        color: #b43709;
        font-size: 14px;
        text-transform: uppercase;
        margin-bottom: 17px;
    }
    a{
        display: block;
        text-decoration: none;
        margin: 0 auto;
        text-align: center;
        @include transition;
        font-weight: 500;
        font-size: 14px;
        color: #ffffff;
        text-transform: uppercase;
        width: 144px;
        height:45px;
        line-height: 45px;
        -webkit-border-radius:3px;
        -moz-border-radius: 3px;
        border-radius: 3px;
        background-color: #53b412;
        -webkit-box-shadow: 0 2px 2px rgba(0, 0, 0, 0.4);
        -moz-box-shadow: 0 2px 2px rgba(0, 0, 0, 0.4);
        box-shadow:0 2px 2px rgba(0, 0, 0, 0.4);
        &:hover{
            @include transition;
            background-color: #5bc017;
        }
    }
}
.autoplay{
    .slick-prev {
        left: 0;
        top:70px;
        margin: 0;
        -webkit-transform: translate(0,0);
        -moz-transform: translate(0,0);
        -ms-transform: translate(0,0);
        -o-transform: translate(0,0);
        transform: translate(0,0);
        height:49px;
        text-indent: -9999px;
        width: 49px;
        background: url(../img/left.png) no-repeat 50% 50% #b43709;
        -webkit-box-shadow: 0 2px 2px rgba(0, 0, 0, 0.4);
        -moz-box-shadow: 0 2px 2px rgba(0, 0, 0, 0.4);
        box-shadow:0 2px 2px rgba(0, 0, 0, 0.4);
        @include transition;
        opacity:1;
        &:before{
            display: none;
        }
        &:hover{
            @include transition;
            opacity:1;
            background-color: #c34618;
        }
    }
    .slick-next {
        right: 0;
        top:70px;
        opacity:1;
        margin: 0;
        -webkit-transform: translate(0,0);
        -moz-transform: translate(0,0);
        -ms-transform: translate(0,0);
        -o-transform: translate(0,0);
        transform: translate(0,0);
        height:49px;
        text-indent: -9999px;
        width: 49px;
        background: url(../img/right.png) no-repeat 50% 50% #b43709;
        -webkit-box-shadow: 0 2px 2px rgba(0, 0, 0, 0.4);
        -moz-box-shadow: 0 2px 2px rgba(0, 0, 0, 0.4);
        box-shadow:0 2px 2px rgba(0, 0, 0, 0.4);
        @include transition;
        &:before{
            display: none;
        }
        &:hover{
            @include transition;
            opacity:1;
            background-color: #c34618;
        }
    }
}
.in-demand2{
    .autoplay{
        .slick-prev{
            background-color: #53b412;
            &:hover{
                background-color: #5bc017;
            }
        }
        .slick-next{
            background-color: #53b412;
            &:hover{
                background-color: #5bc017;
            }
        }
        .slide-block{
            a{
                background-color: #b43709;
                &:hover{
                    background-color: #c34618;
                }
            }
        }
    }
}
.how-works{
    background-color: #f1e8e5;
    padding-top: 70px;
    padding-bottom: 70px;
    -webkit-box-shadow: 0 3px 5px rgba(0, 0, 0, 0.4);
    -moz-box-shadow: 0 3px 5px rgba(0, 0, 0, 0.4);
    box-shadow: 0 3px 5px rgba(0, 0, 0, 0.4);
    margin-bottom: 70px;
    .title{
        color: #b43709;
        margin-bottom: 50px;
        padding: 0 15px;
    }
    .how-works-block{
        display: flex;
        position: relative;
        margin-bottom: 10px;
        img{
            display: block;
            border: 1px solid #45332c;
            position: relative;
            z-index:2;
            min-width: 540px;
        }
        .right{
            padding-top: 26px;
            width: 100%;
            .title{
                background-color: #45332c;
                font-weight: bold;
                font-size: 24px;
                line-height: 28px;
                text-transform: uppercase;
                display: flex;
                align-items: center;
                text-align: left;
                height:119px;
                padding-left: 30px;
                margin-bottom: 0;
                color: #fff;
                position: relative;
            }
            .right-text{
                background-color: #fff;
                padding: 25px 30px 5px 30px;
                min-height: 240px;
                p{
                    display: block;
                    font-size: 14px;
                    color: #352a26;
                    line-height: 20px;
                    margin-bottom: 20px;
                }
                em{
                    display: block;
                    font-style: italic;
                    font-size: 14px;
                    color: #7c7c7c;
                    line-height: 20px;
                }
                span{
                    display: block;
                    font-weight: bold;
                    font-size: 14px;
                    color: #352a26;
                    line-height: 20px;
                    margin-bottom: 12px;
                }
                .instruments{
                    position: relative;
                    z-index:5;
                    ul{
                        display: flex;
                        flex-wrap: wrap;
                        li{
                            margin-right: 14px;
                            padding-bottom: 23px;
                            position: relative;
                            @include transition;
                            &:after{
                                @include after;
                                position: absolute;
                                top: 36px;
                                left: -8px;
                                width: 18px;
                                height:18px;
                                background: url(../img/icon98.png) no-repeat;
                                z-index: 3;
                            }
                            img{
                                display: block;
                                border: 1px solid #E5E3E0;
                                min-width: 1px;
                                width: 44px;
                                height:45px;
                            }
                            .instruments-dop{
                                display: none;
                                position: absolute;
                                top:100%;
                                left: 0;
                                margin-left: 0;
                                background-color: #fff;
                                border: 1px solid #45332c;
                                padding: 8px 20px;
                                em{
                                    display: block;
                                    text-align: center;
                                    font-size: 12px;
                                    color: #b43709;
                                    margin-bottom: 3px;
                                    white-space: nowrap;
                                    font-style: normal;
                                    line-height: 12px;
                                }
                                a{
                                    display: block;
                                    text-align: center;
                                    color: #45332c;
                                    font-size: 12px;
                                    white-space: nowrap;
                                    font-weight: bold;
                                    border-bottom: 1px dashed;
                                    margin: 0;
                                    text-decoration: none;
                                }
                                &:after{
                                    @include after;
                                    position: absolute;
                                    top: -12px;
                                    left: 21px;
                                    background: url(../img/icon100.png) no-repeat;
                                    width: 11px;
                                    height: 13px;
                                }
                            }
                        }
                        li:hover:after{
                            background: url(../img/icon99.png) no-repeat;
                        }
                        li:hover .instruments-dop{
                            display: flex;
                            flex-direction: column;
                            align-items: center;
                            justify-content: center;
                        }
                        li:hover {
                            img{
                                border: 1px solid #49342D;
                                -webkit-box-shadow: 0 3px 3px rgba(0,0,0, 0.5);
                                -moz-box-shadow: 0 3px 3px rgba(0,0,0, 0.5);
                                box-shadow: 0 3px 3px rgba(0,0,0, 0.5);
                            }
                        }
                    }
                }
                .instruments2{
                    ul{
                        li{
                            margin-right: 20px;
                            img{
                                -webkit-border-radius: 100px;
                                -moz-border-radius: 100px;
                                border-radius: 100px;
                            }
                            &:after{
                                top: 36px;
                                left: -2px;
                            }
                        }
                    }
                }
            }
        }
    }
    .how-works-block2{
        &:after{
            background-color: #b43709;
            right: -30px;
            left: auto;
        }
        .right{
            .title{
                background-color: #b43709;
            }
        }
    }
    a{
        margin: 47px auto 0;
    }
}
.btn3{
    cursor: pointer;
    font-weight: bold;
    color: #fff;
    text-transform: uppercase;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    -webkit-box-shadow: 0 2px 2px rgba(0,0,0, 0.4);
    -moz-box-shadow: 0 2px 2px rgba(0,0,0, 0.4);
    box-shadow: 0 2px 2px rgba(0,0,0, 0.4);
    text-decoration: none;
    width: 421px;
    height:70px;
    font-size: 25px;
    -webkit-border-radius: 50px;
    -moz-border-radius: 50px;
    border-radius: 50px;
    background-color: #45332c;
    @include transition;
    &:hover{
        @include transition;
        background-color: #624e46;
    }
}
.how-works a.btn3 {
    margin: 47px auto 0;
    width: 690px;
}
.write-us{
    background: url(../img/img6.jpg) no-repeat 50% 40px;
    min-height: 929px;
    .container{
        display: flex;
        justify-content: flex-end;
        .write-us-block{
            margin-right: 90px;
            -webkit-border-radius: 500px;
            -moz-border-radius: 500px;
            border-radius: 500px;
            background-color: #fff;
            -webkit-box-shadow: 0 5px 12px rgba(0, 0, 0, 0.5);
            -moz-box-shadow: 0 5px 12px rgba(0, 0, 0, 0.5);
            box-shadow: 0 5px 12px rgba(0, 0, 0, 0.5);
            width: 538px;
            height:538px;
            padding-top: 80px;
            span{
                display: block;
                text-align: center;
                font-size: 30px;
                color: #352a26;
                margin-bottom: 15px;
            }
            strong{
                display: block;
                text-align: center;
                font-size: 40px;
                color: #b43709;
                text-transform: uppercase;
                font-weight: 900;
                margin-bottom: 30px;
            }
            .line{
                margin: 0 auto 30px;
                width: 280px;
                height:2px;
                background-color: #352a26;
            }
            input[type="text"] {
                display: block;
                margin: 0 auto 9px;
                color: #352a26;
                font-size: 14px;
                font-family: 'Roboto', sans-serif;
                height:43px;
                padding: 0 18px;
                width: 160px;
                border: 1px solid #352a26;
                -webkit-border-radius: 3px;
                -moz-border-radius: 3px;
                border-radius: 3px;
            }
            .row{
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;
                margin: 0 auto;
                width: 350px;
                input[type="text"]{
                    width: 160px;
                    margin: 0 0 9px 0;
                }
            }
            textarea {
                display: block;
                margin: 0 auto 28px;
                color: #352a26;
                font-size: 14px;
                font-family: 'Roboto', sans-serif;
                height:75px;
                padding: 15px 18px;
                width: 350px;
                border: 1px solid #352a26;
                -webkit-border-radius: 3px;
                -moz-border-radius: 3px;
                border-radius: 3px;
            }
            input[type="submit"] {
                margin: 0 auto;
                border: none;
            }
        }
    }
}
.find-master{
    background-color: #f1e8e5;
    padding-top: 70px;
    padding-bottom: 60px;
    -webkit-box-shadow: 0 3px 5px rgba(0, 0, 0, 0.4);
    -moz-box-shadow: 0 3px 5px rgba(0, 0, 0, 0.4);
    box-shadow: 0 3px 5px rgba(0, 0, 0, 0.4);
    margin-bottom: 70px;
    .find-master-block{
        background-color: #fff;
        padding-top: 50px;
        padding-bottom: 30px;
        .title{
            margin-bottom: 60px;
            b {
                color: #b43709;
            }
        }
        ul{
            display: flex;
            flex-wrap: wrap;
            padding: 0 15px;
            li{
                width: 20%;
                padding: 0;
                margin-bottom: 40px;
                display: flex;
                justify-content: center;
                img{
                    display: block;
                    min-width: 43px;
                    height:42px;
                    margin-right: 10px;
                }
                .service-block2{
                    span{
                        display: block;
                        font-size: 14px;
                        line-height: 20px;
                        color: #352a26;
                        padding-top: 14px;
                        min-height: 70px;
                    }
                    a{
                        display: block;
                        text-decoration: none;
                        margin: 0 auto;
                        text-align: center;
                        -webkit-transition: 0.4s;
                        -moz-transition: 0.4s;
                        -ms-transition: 0.4s;
                        -o-transition: 0.4s;
                        transition: 0.4s;
                        font-weight: 500;
                        font-size: 14px;
                        color: #ffffff;
                        text-transform: uppercase;
                        width: 111px;
                        height: 35px;
                        line-height: 35px;
                        -webkit-border-radius: 3px;
                        -moz-border-radius: 3px;
                        border-radius: 3px;
                        background-color: #53b412;
                        -webkit-box-shadow: 0 2px 2px rgba(0, 0, 0, 0.4);
                        -moz-box-shadow: 0 2px 2px rgba(0, 0, 0, 0.4);
                        box-shadow: 0 2px 2px rgba(0, 0, 0, 0.4);
                        &:hover{
                            -webkit-transition: 0.4s;
                            -moz-transition: 0.4s;
                            -ms-transition: 0.4s;
                            -o-transition: 0.4s;
                            transition: 0.4s;
                            background-color: #5bc017;
                        }
                    }
                }
            }
        }
    }
}
.partners{
    margin-bottom: 50px;
    a.btn1{
        margin: 0 auto 0;
    }
    .title{
        margin-bottom: 58px;
    }
    .slide-block img{
        -webkit-border-radius: 0;
        -moz-border-radius: 0;
        border-radius: 0;
        -webkit-box-shadow: none;
        -moz-box-shadow: none;
        box-shadow: none;
        margin-bottom: 10px;
    }
    .slide-block strong{
        margin: 0;
    }
    .autoplay .slick-prev{
        top: 50%;
        -webkit-transform: translate(0,-50%);
        -moz-transform: translate(0,-50%);
        -ms-transform: translate(0,-50%);
        -o-transform: translate(0,-50%);
        transform: translate(0,-50%);
    }
    .autoplay .slick-next{
        top: 50%;
        -webkit-transform: translate(0,-50%);
        -moz-transform: translate(0,-50%);
        -ms-transform: translate(0,-50%);
        -o-transform: translate(0,-50%);
        transform: translate(0,-50%);
    }
    .autoplay{
        margin-bottom: 55px;
    }
}
.footer{
    background-color: #45332c;
    padding: 20px 0;
    .container{
        .nav{
            margin-bottom: 15px;
            ul{
                display: flex;
                flex-wrap: wrap;
                justify-content: center;
                li{
                    background: url(../img/separator.png) no-repeat 100% 0;
                    padding-right: 25px;
                    margin-right: 22px;
                    &:last-child{
                        background: none;
                        padding: 0;
                        margin: 0;
                    }
                    a{
                        display: block;
                        text-transform: uppercase;
                        text-decoration: none;
                        color: #fff;
                        font-size: 12px;
                        padding-bottom: 10px;
                        border-bottom: 2px solid #45332c;
                        @include transition;
                        &:hover{
                            border-bottom: 2px solid #b43709;
                            @include transition;
                        }
                    }
                }
                li.active a{
                    font-weight: 900;
                    border-bottom: 2px solid #b43709;
                }
            }
        }
        .footer-container{
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            .footer-logo{
                display: block;
                background: url(../img/logo2.png) no-repeat;
                width: 278px;
                height:89px;
                a{
                    display: block;
                    height:100%;
                }
            }
            .center{
                padding-top: 20px;
                a{
                    display: block;
                    text-decoration: none;
                    font-size: 12px;
                    color: #fff;
                    border-bottom: 1px dashed;
                    padding-bottom: 4px;
                    -webkit-transition: 0.4s;
                    -moz-transition: 0.4s;
                    -ms-transition: 0.4s;
                    -o-transition: 0.4s;
                    transition: 0.4s;
                }
            }
            .right{
                .tel{
                    display: flex;
                    padding-left: 26px;
                    justify-content: center;
                    align-items: center;
                    background: url(../img/icon48.png) 0px 50% no-repeat;
                    margin-bottom: 20px;
                    strong{
                        display: block;
                        font-weight: bold;
                        font-size: 28px;
                        color: #b43709;
                        font-family: 'Swiss721BlackCondensedBT';
                        margin-right: 14px;
                    }
                    a{
                        display: block;
                        text-decoration: none;
                        font-size: 12px;
                        color: #fff;
                        border-bottom: 1px dashed;
                        padding-bottom: 4px;
                        -webkit-transition: 0.4s;
                        -moz-transition: 0.4s;
                        -ms-transition: 0.4s;
                        -o-transition: 0.4s;
                        transition: 0.4s;
                    }
                }
                .soc{
                    display: flex;
                    align-items: center;
                    flex-wrap: wrap;
                    justify-content: center;
                    span{
                        display: block;
                        margin-right: 17px;
                        color: #fff;
                        font-size: 14px;
                    }
                    ul{
                        display: flex;
                        li{
                            margin-right: 7px;
                            &:last-child{
                                margin: 0;
                            }
                            a{
                                display: block;
                                overflow: hidden;
                                -webkit-border-radius: 5px;
                                -moz-border-radius: 5px;
                                border-radius: 5px;
                                width: 31px;
                                height:31px;
                                position: relative;
                                top:0;
                                @include transition;
                                &:hover{
                                    @include transition;
                                    top: 5px;
                                }
                            }
                        }
                        li a.vk{
                            background: url(../img/soc1.png) no-repeat;
                        }
                        li a.ok{
                            background: url(../img/soc2.png) no-repeat;
                        }
                        li a.f{
                            background: url(../img/soc3.png) no-repeat;
                        }
                        li a.inst{
                            background: url(../img/soc4.png) no-repeat;
                        }
                        li a.youtube{
                            background: url(../img/soc5.png) no-repeat;
                        }
                    }
                }
            }
        }
    }
    .bottom{
        display: flex;
        justify-content: center;
        align-items: center;
        span{
            display: block;
            min-height: 28px;
            background: url(../img/icon47.png) no-repeat 0 50%;
            padding-left: 47px;
            font-size: 12px;
            text-transform: uppercase;
            color: #fff;
            font-family: 'PT Sans', sans-serif;
            line-height: 28px;
            a{
                text-decoration: underline;
                color: #fff;
                &:hover{
                    text-decoration: none;
                }
            }
        }
    }
}
.popup1{
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height:100%;
    z-index:99;
    .close1{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height:100%;
        cursor: pointer;
        background-color: rgba(0,0,0, 0.5);
    }
    .popup-container{
        position: absolute;
        top:100px;
        left: 50%;
        -webkit-transform: translate(-50%,0);
        -moz-transform: translate(-50%,0);
        -ms-transform: translate(-50%,0);
        -o-transform: translate(-50%,0);
        transform: translate(-50%,0);
        z-index:2;
        padding: 0 15px;
        .popup-block{
            width: 410px;
            background-color: #fff;
            position: relative;
            padding: 35px 15px 28px;
            .close2{
                position: absolute;
                cursor: pointer;
                top: -47px;
                right: -47px;
                background: url(../img/close.png) no-repeat;
                width: 47px;
                height:47px;
            }
            .form-popup{
                max-width:285px;
                margin: 0 auto;
                strong{
                    display: block;
                    text-align: center;
                    text-transform: uppercase;
                    color: #b43709;
                    font-size: 26px;
                    font-weight: bold;
                    margin-bottom: 25px;
                }
                input[type="text"] {
                    display: block;
                    width: 100%;
                    font-size: 14px;
                    color: #46332d;
                    font-family: 'Roboto', sans-serif;
                    padding: 0 20px 0 32px;
                    margin-bottom: 17px;
                    background-color: #c0b8b6;
                    border: 1px solid #fff;
                    -webkit-box-shadow: 0 3px 9px rgba(0, 0, 0, 0.5);
                    -moz-box-shadow: 0 3px 9px rgba(0, 0, 0, 0.5);
                    box-shadow: 0 3px 9px rgba(0, 0, 0, 0.5);
                    height:40px;
                }
                input[type="password"] {
                    display: block;
                    width: 100%;
                    font-size: 14px;
                    color: #46332d;
                    font-family: 'Roboto', sans-serif;
                    padding: 0 20px 0 32px;
                    margin-bottom: 17px;
                    background-color: #c0b8b6;
                    border: 1px solid #fff;
                    -webkit-box-shadow: 0 3px 9px rgba(0, 0, 0, 0.5);
                    -moz-box-shadow: 0 3px 9px rgba(0, 0, 0, 0.5);
                    box-shadow: 0 3px 9px rgba(0, 0, 0, 0.5);
                    height:40px;
                }
                .input-placeholder-text {
                    line-height: 40px;
                    padding-left: 32px;
                    font-size: 14px;
                    color: #46332d;
                    font-family: 'Roboto', sans-serif;
                }
                .check{
                    margin-top: 22px;
                    display: flex;
                    justify-content: center;
                    margin-bottom: 35px;
                    input[type="checkbox"] {
                        display: none;
                    }
                    input[type="checkbox"]+label {
                        cursor:pointer;
                        color:#46332d;
                        font-size: 12px;
                        font-family: 'Roboto', sans-serif;
                        font-style: italic;
                        display:block;
                        padding:5px 0 0 27px;
                        background: url(../img/check.png) no-repeat 0 50%;
                        min-height: 19px;
                    }
                    input[type="checkbox"]:checked + label {
                        background: url(../img/check2.png) no-repeat 0 50%;
                    }
                }
                input[type="submit"] {
                    display: block;
                    cursor: pointer;
                    width: 100%;
                    text-align: center;
                    color:#fff;
                    text-transform: uppercase;
                    font-size: 18px;
                    font-family: 'Roboto', sans-serif;
                    font-weight: bold;
                    height:50px;
                    background-color: #b43709;
                    @include transition;
                    -webkit-box-shadow: 0 5px 17px rgba(132,53,23, 0.5);
                    -moz-box-shadow: 0 5px 17px rgba(132,53,23, 0.5);
                    box-shadow: 0 5px 17px rgba(132,53,23, 0.5);
                    margin-bottom: 20px;
                    border: none;
                    &:hover{
                        @include transition;
                        background-color: #c34618;
                    }
                }
                a{
                    display: block;
                    text-align: center;
                    text-decoration: underline;
                    color: #46332d;
                    font-size: 12px;
                    font-style: italic;
                    &:hover{
                        text-decoration: none;
                    }
                }
            }
        }
    }
}
.popup2{
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height:100%;
    z-index:99;
    .close3{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height:100%;
        cursor: pointer;
        background-color: rgba(0,0,0, 0.5);
    }
    .popup-container{
        position: absolute;
        top:100px;
        left: 50%;
        -webkit-transform: translate(-50%,0);
        -moz-transform: translate(-50%,0);
        -ms-transform: translate(-50%,0);
        -o-transform: translate(-50%,0);
        transform: translate(-50%,0);
        z-index:2;
        padding: 0 15px;
        .popup-block{
            width: 410px;
            background-color: #fff;
            position: relative;
            padding: 35px 15px 28px;
            .close4{
                position: absolute;
                cursor: pointer;
                top: -47px;
                right: -47px;
                background: url(../img/close.png) no-repeat;
                width: 47px;
                height:47px;
            }
            .form-popup{
                max-width:300px;
                margin: 0 auto;
                strong{
                    display: block;
                    text-align: center;
                    text-transform: uppercase;
                    color: #b43709;
                    font-size: 26px;
                    font-weight: bold;
                    margin-bottom: 25px;
                }
                input[type="text"] {
                    display: block;
                    width: 100%;
                    font-size: 14px;
                    color: #46332d;
                    font-family: 'Roboto', sans-serif;
                    padding: 0 20px 0 32px;
                    margin-bottom: 17px;
                    background-color: #c0b8b6;
                    border: 1px solid #fff;
                    -webkit-box-shadow: 0 3px 9px rgba(0, 0, 0, 0.5);
                    -moz-box-shadow: 0 3px 9px rgba(0, 0, 0, 0.5);
                    box-shadow: 0 3px 9px rgba(0, 0, 0, 0.5);
                    height:40px;
                }
                input[type="password"] {
                    display: block;
                    width: 100%;
                    font-size: 14px;
                    color: #46332d;
                    font-family: 'Roboto', sans-serif;
                    padding: 0 20px 0 32px;
                    margin-bottom: 17px;
                    background-color: #c0b8b6;
                    border: 1px solid #fff;
                    -webkit-box-shadow: 0 3px 9px rgba(0, 0, 0, 0.5);
                    -moz-box-shadow: 0 3px 9px rgba(0, 0, 0, 0.5);
                    box-shadow: 0 3px 9px rgba(0, 0, 0, 0.5);
                    height:40px;
                }
                .input-placeholder-text {
                    line-height: 40px;
                    padding-left: 32px;
                    font-size: 14px;
                    color: #46332d;
                    font-family: 'Roboto', sans-serif;
                }
                .check{
                    margin-top: 22px;
                    display: flex;
                    justify-content: center;
                    margin-bottom: 35px;
                    input[type="checkbox"] {
                        display: none;
                    }
                    input[type="checkbox"]+label {
                        cursor:pointer;
                        color:#46332d;
                        font-size: 12px;
                        font-family: 'Roboto', sans-serif;
                        font-style: italic;
                        display:block;
                        padding:5px 0 0 24px;
                        background: url(../img/check.png) no-repeat 0 50%;
                        min-height: 19px;
                    }
                    input[type="checkbox"]:checked + label {
                        background: url(../img/check2.png) no-repeat 0 50%;
                    }
                }
                input[type="submit"] {
                    display: block;
                    cursor: pointer;
                    width: 100%;
                    text-align: center;
                    color:#fff;
                    text-transform: uppercase;
                    font-size: 18px;
                    font-family: 'Roboto', sans-serif;
                    font-weight: bold;
                    height:50px;
                    background-color: #b43709;
                    @include transition;
                    -webkit-box-shadow: 0 5px 17px rgba(132,53,23, 0.5);
                    -moz-box-shadow: 0 5px 17px rgba(132,53,23, 0.5);
                    box-shadow: 0 5px 17px rgba(132,53,23, 0.5);
                    margin-bottom: 20px;
                    border: none;
                    &:hover{
                        @include transition;
                        background-color: #c34618;
                    }
                }
                a{
                    display: inline;
                    text-align: center;
                    text-decoration: underline;
                    color: #46332d;
                    font-size: 12px;
                    font-style: italic;
                    &:hover{
                        text-decoration: none;
                    }
                }
                em{
                    display: block;
                    font-style: italic;
                    font-size: 12px;
                    text-align: center;
                    color: #46332d;
                    margin-bottom: 11px;
                }
                .buttons {
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: space-between;
                    a.btn2{
                        display: block;
                        font-weight: bold;
                        font-size: 15px;
                        text-transform: uppercase;
                        color: #fff;
                        text-decoration: none;
                        width: 144px;
                        height:42px;
                        line-height: 42px;
                        font-style: normal;
                        margin: 0 0 27px 0;
                    }
                    a.btn1{
                        display: block;
                        font-weight: bold;
                        font-size: 15px;
                        font-style: normal;
                        margin: 0 0 27px 0;
                        text-transform: uppercase;
                        color: #fff;
                        text-decoration: none;
                        width: 144px;
                        height:42px;
                        line-height: 42px;
                    }
                }
            }
        }
    }
}
.header2{
    margin-bottom: 18px;
}
.header .bottom2{
    .container{
        flex-wrap: nowrap;
        .logo{
            min-width: 270px;
            margin-right: 30px;
        }
        .tel{
            margin-left:30px;
            min-width: 190px;
        }
    }
    .search-master-block{
        margin: 0;
        width: 100%;
        form input[type="submit"] {
            cursor: pointer;
            background: url(../img/icon5.png) no-repeat 22px 50% #b43709;
            display: block;
            font-family: 'Roboto', sans-serif;
            border: none;
            padding-left: 53px;
            min-width: 147px;
            font-size: 20px;
            color: #fff;
            height: 60px;
            font-weight: 500;
            text-align: left;
            -webkit-transition: 0.4s;
            -moz-transition: 0.4s;
            -ms-transition: 0.4s;
            -o-transition: 0.4s;
            transition: 0.4s;
            &:hover{
                -webkit-transition: 0.4s;
                -moz-transition: 0.4s;
                -ms-transition: 0.4s;
                -o-transition: 0.4s;
                transition: 0.4s;
                background: url(../img/icon5.png) no-repeat 22px 50% #c34618;
            }
        }
        form a.rubric {
            display: block;
            color: #fff;
            font-weight: 500;
            min-width: 147px;
            height: 60px;
            line-height: 60px;
            background: url(../img/icon4.png) no-repeat 15px 50% #49342D;
            padding-left: 51px;
            text-decoration: none;
            font-size: 20px;
        }
    }
}
.inner-page{
    background-color: #f1e8e5;
    padding: 45px 0 60px;
    -webkit-box-shadow: 0 3px 5px rgba(0, 0, 0, 0.4);
    -moz-box-shadow: 0 3px 5px rgba(0, 0, 0, 0.4);
    box-shadow: 0 3px 5px rgba(0, 0, 0, 0.4);
    margin-bottom: 25px;
}
.title-inner{
    display: block;
    padding: 0 15px;
    font-size: 40px;
    color: #b43709;
    text-transform: uppercase;
    text-align: center;
    font-weight: 900;
    margin-bottom: 46px;
}
.banner2{
    background: url(../img/img9.jpg) no-repeat 50% 0;
    min-height: 570px;
    -webkit-box-shadow: 0 3px 5px rgba(0, 0, 0, 0.4);
    -moz-box-shadow: 0 3px 5px rgba(0, 0, 0, 0.4);
    box-shadow: 0 3px 5px rgba(0, 0, 0, 0.4);
    margin-bottom: 17px;
}
.inner-page-block{
    background-color: #fff;
    padding:60px 90px 40px;
    .text{
        strong{
            display: block;
            font-weight: bold;
            margin-bottom: 25px;
            color: #b43709;
            font-size: 26px;
        }
        p{
            display: block;
            margin-bottom: 25px;
            color: #352a26;
            font-size: 14px;
            line-height: 20px;
            b{
                font-weight: bold;
            }
        }
        span{
            display: block;
            font-weight: bold;
            margin-bottom: 25px;
            color: #352a26;
            font-size: 14px;
            line-height: 20px;
        }
        ul{
            margin-bottom: 25px;
            li{
                padding-left: 10px;
                background: url(../img/bg-li.png) no-repeat 0 9px;
                color: #352a26;
                font-size: 14px;
                line-height: 20px;
            }
        }
    }
}
.contacts{
    display: flex;
    margin-bottom: 30px;
    .contacts-left{
        width: 350px;
        background-color: #45332c;
        padding: 27px 30px;
        margin-right: 37px;
        margin-bottom: 30px;
        strong{
            display: block;
            text-align: center;
            text-transform: uppercase;
            font-weight: bold;
            font-size: 26px;
            margin-bottom: 27px;
            color: #fff;
        }
        input[type="text"] {
            display: block;
            width: 100%;
            font-size: 14px;
            color: #fff;
            font-family: 'Roboto', sans-serif;
            padding: 0 20px 0 32px;
            margin-bottom: 17px;
            background-color: #352a26;
            border: 1px solid #fff;
            -webkit-box-shadow: 0 3px 9px rgba(0, 0, 0, 0.5);
            -moz-box-shadow: 0 3px 9px rgba(0, 0, 0, 0.5);
            box-shadow: 0 3px 9px rgba(0, 0, 0, 0.5);
            height: 40px;
        }
        textarea {
            display: block;
            width: 100%;
            font-size: 14px;
            color: #fff;
            font-family: 'Roboto', sans-serif;
            padding: 15px 20px 15px 32px;
            margin-bottom: 17px;
            background-color: #352a26;
            border: 1px solid #fff;
            -webkit-box-shadow: 0 3px 9px rgba(0, 0, 0, 0.5);
            -moz-box-shadow: 0 3px 9px rgba(0, 0, 0, 0.5);
            box-shadow: 0 3px 9px rgba(0, 0, 0, 0.5);
            height: 90px;
        }
        input[type="submit"] {
            display: block;
            cursor: pointer;
            width: 100%;
            text-align: center;
            color: #fff;
            text-transform: uppercase;
            font-size: 18px;
            font-family: 'Roboto', sans-serif;
            font-weight: bold;
            height: 50px;
            background-color: #b43709;
            -webkit-transition: 0.4s;
            -moz-transition: 0.4s;
            -ms-transition: 0.4s;
            -o-transition: 0.4s;
            transition: 0.4s;
            -webkit-box-shadow: 0 5px 17px rgba(132, 53, 23, 0.5);
            -moz-box-shadow: 0 5px 17px rgba(132, 53, 23, 0.5);
            box-shadow: 0 5px 17px rgba(132, 53, 23, 0.5);
            border: none;
            &:hover{
                -webkit-transition: 0.4s;
                -moz-transition: 0.4s;
                -ms-transition: 0.4s;
                -o-transition: 0.4s;
                transition: 0.4s;
                background-color: #c34618;
            }
        }
    }
    .contacts-right{
        padding-top: 30px;
        strong{
            display: block;
            font-size: 26px;
            color: #b43709;
            font-weight: bold;
            margin-bottom: 47px;
        }
        b{
            display: block;
            font-size: 14px;
            color: #352a26;
            font-weight: bold;
            margin-bottom: 10px;
        }
        span{
            display: block;
            font-size: 16px;
            color: #352a26;
            margin-bottom: 30px;
        }
        .soc{
            ul{
                display: flex;
                li{
                    margin-right: 8px;
                    a{
                        display: block;
                        overflow: hidden;
                        -webkit-border-radius: 5px;
                        -moz-border-radius: 5px;
                        border-radius: 5px;
                        width: 31px;
                        height: 31px;
                        position: relative;
                        top: 0;
                        -webkit-transition: 0.4s;
                        -moz-transition: 0.4s;
                        -ms-transition: 0.4s;
                        -o-transition: 0.4s;
                        transition: 0.4s;
                        &:hover{
                            -webkit-transition: 0.4s;
                            -moz-transition: 0.4s;
                            -ms-transition: 0.4s;
                            -o-transition: 0.4s;
                            transition: 0.4s;
                            top: 5px;
                        }
                    }
                }
                li a.vk{
                    background: url(../img/soc1.png) no-repeat;
                }
                li a.ok{
                    background: url(../img/soc2.png) no-repeat;
                }
                li a.f{
                    background: url(../img/soc3.png) no-repeat;
                }
                li a.inst{
                    background: url(../img/soc4.png) no-repeat;
                }
                li a.youtube{
                    background: url(../img/soc5.png) no-repeat;
                }
            }
        }
    }
}
.banner3{
    background: url(../img/img10.jpg) no-repeat 50% 0;
    min-height: 570px;
    -webkit-box-shadow: 0 3px 5px rgba(0, 0, 0, 0.4);
    -moz-box-shadow: 0 3px 5px rgba(0, 0, 0, 0.4);
    box-shadow: 0 3px 5px rgba(0, 0, 0, 0.4);
    margin-bottom: 17px;
}
.banner4{
    background: url(../img/img14.jpg) no-repeat 50% 0;
    min-height: 570px;
    -webkit-box-shadow: 0 3px 5px rgba(0, 0, 0, 0.4);
    -moz-box-shadow: 0 3px 5px rgba(0, 0, 0, 0.4);
    box-shadow: 0 3px 5px rgba(0, 0, 0, 0.4);
    margin-bottom: 17px;
}
.inner-page-block2{
    padding: 96px 60px 0;
    overflow: hidden;
    .steps{
        ul{
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            position: relative;
            &:after{
                @include after;
                position: absolute;
                top: -50px;
                left: 55px;
                background: url(../img/bg.png) no-repeat;
                width: 864px;
                height:181px;
            }
            li{
                margin-bottom: 60px;
                position: relative;
                z-index:2;
                .iconka{
                    margin: 0 auto 45px;
                    background-color: #bfb8b6;
                    -webkit-border-radius: 100px;
                    -moz-border-radius: 100px;
                    border-radius: 100px;
                    border: 3px solid #fff;
                    -webkit-box-shadow: 0 3px 9px rgba(0, 0, 0, 0.54);
                    -moz-box-shadow: 0 3px 9px rgba(0, 0, 0, 0.54);
                    box-shadow: 0 3px 9px rgba(0, 0, 0, 0.54);
                    width: 95px;
                    height: 95px;
                    b{
                        display: block;
                        width: 100%;
                        height: 100%;
                        background: url(../img/icon49.png) no-repeat 50% 50%;
                    }
                }
                strong{
                    display: block;
                    text-align: center;
                    font-weight: bold;
                    font-size: 26px;
                    color: #b43709;
                    margin-bottom: 7px;
                }
                span{
                    display: block;
                    text-align: center;
                    font-size: 14px;
                    color: #352a26;
                    line-height: 16px;
                }
            }
        }
    }

}
.good-know{
    .title{
        margin-bottom: 60px;
    }
}
.good-know-block{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 70px;
    .left{
        width: 41%;
        img{
            display: block;
            width: 100%;
            height:auto;
        }
    }
    .right{
        width: 54%;
        strong{
            display: block;
            font-weight: bold;
            margin-bottom: 25px;
            color: #b43709;
            font-size: 26px;
        }
        p{
            display: block;
            margin-bottom: 25px;
            color: #352a26;
            font-size: 14px;
            line-height: 20px;
            b{
                font-weight: bold;
            }
        }
        span{
            display: block;
            font-weight: bold;
            margin-bottom: 25px;
            color: #352a26;
            font-size: 14px;
            line-height: 20px;
        }
        ul{
            margin-bottom: 25px;
            li{
                padding-left: 10px;
                background: url(../img/bg-li.png) no-repeat 0 9px;
                color: #352a26;
                font-size: 14px;
                line-height: 20px;
            }
        }
        a.btn1{
            font-size: 13px;
            width: 158px;
            height:39px;
            line-height: 39px;
        }
    }
}
.good-know-block:nth-child(odd) {
    .left{
        order: 2;
    }
}
.inner-page-block3 {
    padding: 50px 40px;
    overflow: hidden;
    .good-know-block{
        margin-bottom: 0;
        .left{
            width: 43%;
        }
        .right{
            width: 53%;
        }
    }
    .good-know-block2{
        .left{
            order: 1;
        }
        .right{
            order: 2;
        }
    }
}
.banner5{
    background: url(../img/img16.jpg) no-repeat 50% 0;
    min-height: 570px;
    -webkit-box-shadow: 0 3px 5px rgba(0, 0, 0, 0.4);
    -moz-box-shadow: 0 3px 5px rgba(0, 0, 0, 0.4);
    box-shadow: 0 3px 5px rgba(0, 0, 0, 0.4);
    margin-bottom: 17px;
}
.contacts2{
    margin-bottom: 40px;
    justify-content: space-between;
    .contacts-left{
        min-width: 350px;
        margin: 0 0 30px 40px;
    }
}
.partners2{
    margin-bottom: 30px;
    span{
        display: block;
        text-align: center;
        text-transform: uppercase;
        font-weight: 500;
        font-size: 22px;
        color: #45332c;
        margin-bottom: 65px;
    }
    ul{
        display: flex;
        flex-wrap: wrap;
        margin: 0 -40px;
        li{
            width: 25%;
            margin-bottom: 55px;
            a{
                display: block;
                text-decoration: none;
            }
            img{
                display: block;
                margin: 0 auto 10px;
            }
            span{
                display: block;
                text-align: center;
                color: #352a26;
                font-size: 16px;
                text-transform: none;
                font-weight: normal;
                margin-bottom: 8px;
            }
            strong{
                display: block;
                text-align: center;
                font-weight: 900;
                color: #b43709;
                font-size: 14px;
                text-transform: uppercase;
            }
        }
    }
}
.inner-page2{
    padding-top: 22px;
    margin-bottom: 70px;
    .title{
        margin-bottom: 35px;
    }
    .title2{
        padding: 0 15px;
        color: #fff;
        font-size: 22px;
        text-transform: uppercase;
        font-weight: 500;
        text-align: center;
        height:84px;
        display: flex;
        align-items: center;
        line-height: 26px;
        justify-content: center;
        background-color: #45332c;
        margin-bottom: 60px;
    }
}
.bread-crumbs{
    max-width: 1140px;
    padding: 0 15px;
    margin: 0 auto 32px;
    ul{
        display: flex;
        flex-wrap: wrap;
        li{
            padding-right: 17px;
            margin-bottom: 5px;
            margin-right: 11px;
            background: url(../img/bg-li2.png) no-repeat 100% 50%;
            &:last-child{
                margin: 0;
                padding: 0;
                background: none;
            }
            font-size: 14px;
            color: #352a26;
            a{
                display: block;
                text-decoration: none;
                font-size: 14px;
                color: #352a26;
                &:hover{
                    text-decoration: underline;
                }
            }
        }
    }
}
.graph-container{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    .graph-left{
        width: 52%;
        img{
            display: block;
            width: 100%;
            height:auto;
        }
    }
    .graph-right{
        width: 43%;
        background-color: #fff;
        padding: 35px 35px 1px 35px;
        position: relative;
        &:after{
            @include after;
            position: absolute;
            top:46px;
            left: -28px;
            background: url(../img/icon50.png) no-repeat;
            width: 29px;
            height:35px;
        }
        p{
            display: block;
            color: #352a26;
            line-height: 22px;
            font-size: 16px;
            color: #352a26;
            margin-bottom: 20px;
        }
        b{
            display: block;
            font-weight: bold;
            line-height: 22px;
            font-size: 18px;
            color: #b43709;
            margin-bottom: 20px;
        }
    }
}
.question{
    margin-bottom: 70px;
    .title{
        line-height: 46px;
        margin-bottom: 60px;
        b{
            display: block;
            color: #b43709;
        }
    }
    ul{
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        margin: 0 -15px;
        li{
            margin-bottom: 17px;
            width: 50%;
            padding: 0 15px;
            display: flex;
            justify-content: space-between;
            flex-wrap: nowrap;
            .letter{
                min-width: 112px;
                .letter-block{
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    text-align: center;
                    color: #352a26;
                    font-size: 26px;
                    font-weight: 900;
                    width: 65px;
                    height:65px;
                    -webkit-border-radius: 100px;
                    -moz-border-radius: 100px;
                    border-radius: 100px;
                    border:3px solid #352a26;
                }
            }
            .question-block{
                width: 100%;
                background-color: #f1e8e5;
                position: relative;
                padding: 20px 25px;
                display: flex;
                align-items: center;
                &:after{
                    @include after;
                    position: absolute;
                    top:14px;
                    left: -30px;
                    background: url(../img/icon51.png) no-repeat;
                    width: 31px;
                    height:37px;
                }
                p{
                    display: block;
                    font-size: 16px;
                    color: #352a26;
                    line-height: 22px;
                }
            }
        }
        li:nth-child(even) {
            .letter{
                order: 2;
                overflow: hidden;
                .letter-block{
                    float: right;
                }
            }
            .question-block{
                &:after{
                    top:14px;
                    left: auto;
                    right: -30px;
                    background: url(../img/icon52.png) no-repeat;
                }
            }
        }
    }
}
.main{
    display: flex;
    flex-wrap:nowrap;
}
.sidebar{
    min-width: 255px;
    margin-right: 30px;
    width: 255px;
    position: relative;
    z-index: 7;
}
.rubric-block{
    margin-bottom: 20px;
    .btn-rubric{
        cursor: pointer;
        position: relative;
        padding-left: 25px;
        background-color: #45332c;
        -webkit-box-shadow: 0 2px 2px rgba(0,1,1, 0.4);
        -moz-box-shadow: 0 2px 2px rgba(0,1,1, 0.4);
        box-shadow: 0 2px 2px rgba(0,1,1, 0.4);
        height:45px;
        line-height: 47px;
        width: 100%;
        color: #fff;
        font-size: 14px;
        text-transform: uppercase;
        &:after{
            @include after;
            position: absolute;
            top: 50%;
            right: 15px;
            background: url(../img/icon53.png) no-repeat;
            width: 16px;
            height:11px;
            -webkit-transform: translate(0,-50%);
            -moz-transform: translate(0,-50%);
            -ms-transform: translate(0,-50%);
            -o-transform: translate(0,-50%);
            transform: translate(0,-50%);
        }
    }
}
.rubric-dop{
    display: block;
    background-color: #fff;
    border:1px solid #45332c;
    border-top: none;
    padding-top: 20px;
    padding-bottom: 10px;
}
/*accordion*/
.example-list {
    overflow: hidden;
    margin:0;
    width: 100%;
}
.accordion {
    padding:0;
    margin:0;
    list-style:none;
    width:100%;
}
.accordion li {
    margin: 0 0 11px 0;
}
.accordion .opener {
    display:block;
    color:#45332c;
    text-decoration:none;
    font-size: 14px;
    padding:0 10px 0 25px;
    background: url(../img/icon54.png) no-repeat 11px 3px;
    &:hover{
        text-decoration: underline;
    }
}
.accordion li.active .opener,
.accordion li.active li.active .opener {
    background: url(../img/icon55.png) no-repeat 11px 3px;
    text-decoration: underline;
    font-weight: 900;
}
.accordion-dop{
    padding-left: 45px;
    padding-top: 10px;
    a{
        display: block;
        color:#45332c;
        text-decoration:none;
        font-size: 13px;
        &:hover{
            text-decoration: underline;
        }
    }
}
.maybe-interested{
    background-color: #fff;
    padding:20px 13px 0 13px;
    overflow: hidden;
    margin-bottom: 20px;
    .title-interested{
        display: block;
        text-align: center;
        font-weight: bold;
        font-size: 18px;
        line-height: 22px;
        color: #b43709;
        margin-bottom: 15px;
    }
    .master{
        margin-bottom: 20px;
        .top{
            display: flex;
            margin-bottom: 14px;
            .foto-master{
                -webkit-border-radius: 100px;
                -moz-border-radius: 100px;
                border-radius: 100px;
                width: 82px;
                height:82px;
                margin-right: 10px;
                img{
                    display: block;
                    border: 1px solid #45332c;
                    -webkit-border-radius: 100px;
                    -moz-border-radius: 100px;
                    border-radius: 100px;
                    width: 100%;
                    height:auto;
                }
            }
            .info-master{
                padding-top: 10px;
                span{
                    display: block;
                    text-decoration: underline;
                    color: #352a26;
                    font-size: 14px;
                    margin-bottom: 5px;
                }
                b{
                    display: block;
                    text-transform: uppercase;
                    color: #b43709;
                    font-size: 12px;
                    margin-bottom: 9px;
                }
                a{
                    display: block;
                    text-align: center;
                    -webkit-border-radius: 3px;
                    -moz-border-radius: 3px;
                    border-radius: 3px;
                    font-weight: 500;
                    color: #fff;
                    text-decoration: none;
                    font-size: 12px;
                    text-transform: uppercase;
                    width: 93px;
                    height:29px;
                    line-height: 31px;
                    background-color: #53b412;
                    -webkit-box-shadow: 0 2px 2px rgba(0,1,1, 0.4);
                    -moz-box-shadow: 0 2px 2px rgba(0,1,1, 0.4);
                    box-shadow: 0 2px 2px rgba(0,1,1, 0.4);
                    @include transition;
                    margin: 0;
                    &:hover{
                        background-color: #5bc017;
                    }
                }
            }
        }
        .bottom{
            padding: 0 0 0 5px;
            ul{
                li{
                    display: flex;
                    justify-content: space-between;
                    margin-bottom: 7px;
                    span{
                        display: block;
                        color: #352a26;
                        font-size: 14px;
                    }
                    a{
                        display: block;
                        color: #352a26;
                        font-size: 14px;
                        text-decoration: underline;
                        &:hover{
                            text-decoration: none;
                        }
                    }
                    strong{
                        display: block;
                        color: #352a26;
                        font-size: 16px;
                        font-weight: bold;
                        text-align: left;
                        min-width: 30px;
                    }
                }
            }
        }
    }
}
.bulletin-board{
    border: 1px solid #45332c;
    overflow: hidden;
    background-color: #fff;
    .title-bulletin-board{
        background-color: #45332c;
        height:52px;
        color: #fff;
        font-size: 18px;
        font-weight: bold;
        line-height: 52px;
        text-align: center;
        margin-bottom: 20px;
    }
    ul{
        padding: 0 13px;
        li{
            margin-bottom: 46px;
            display: flex;
            flex-wrap: nowrap;
            .user{
                -webkit-border-radius: 100px;
                -moz-border-radius: 100px;
                border-radius: 100px;
                min-width: 41px;
                width: 41px;
                height:41px;
                margin-right: 6px;
                -webkit-box-shadow: 0 1px 4px rgba(0, 0, 0, 0.4);
                -moz-box-shadow: 0 1px 4px rgba(0, 0, 0, 0.4);
                box-shadow: 0 1px 4px rgba(0, 0, 0, 0.4);
                img{
                    display: block;
                    -webkit-border-radius: 100px;
                    -moz-border-radius: 100px;
                    border-radius: 100px;
                    width: 100%;
                    height:auto;
                }
            }
            .ad{
                background-color: #F4E8E3;
                padding: 7px 10px 10px 10px;
                border-bottom: 1px dashed #392C29;
                position: relative;
                width: 100%;
                &:after{
                    @include after;
                    background: url(../img/icon56.png) repeat-x 0 0;
                    height:25px;
                    width: 100%;
                    position: absolute;
                    bottom: -26px;
                    left: 0;
                }
                a{
                    display: block;
                    text-decoration: underline;
                    color: #352a26;
                    font-size: 12px;
                    margin-bottom: 3px;
                    &:hover{
                    text-decoration: none}
                }
                b{
                    display: block;
                    text-transform: uppercase;
                    color: #b43709;
                    font-size: 12px;
                    margin-bottom: 5px;
                }
                p{
                    display: block;
                    color: #352a26;
                    white-space: normal;
                    font-size: 11px;
                    line-height: 14px;
                }
            }
        }
    }
}
.main-container{
    width: 100%;
    background-color: #fff;
}
.top-main{
    position: relative;
    border-bottom: 1px solid #392C29;
    padding-left: 25px;
    min-height: 50px;
    display: flex;
    padding-right: 20px;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 25px;
    &:after{
        @include after;
        position: absolute;
        bottom: -2px;
        left: 25px;
        background-color: #b43709;
        width: 80px;
        height:3px;
    }
    span{
        display: block;
        color: #b43709;
        font-size: 14px;
    }
    .buttons{
        display: flex;
        flex-wrap: wrap;
        a{
            display: block;
            margin-left: 10px;
            text-decoration: none;
            border: 1px solid #392C29;
            padding: 0 15px;
            height:30px;
            text-align: center;
            line-height: 28px;
            color: #352a26;
            @include transition;
            &.active{
                color: #fff;
                background-color: #b43709;
            }
            &:hover{
                @include transition;
                color: #fff;
                background-color: #b43709;
            }
        }
    }
}
.all-services-container{
    padding-left: 30px;
    ul{
        li{
            margin-bottom: 22px;
            a{
                display: flex;
                text-decoration: none;
                align-items: center;
                span{
                    text-decoration: underline;
                    font-weight: bold;
                    color: #352a26;
                    font-size: 16px;
                }
                b{
                    font-weight: bold;
                    color: #352a26;
                    margin-left: 3px;
                    font-size: 12px;
                }
                &:hover span{
                    text-decoration: none;
                }
            }
            ul{
                padding-top: 13px;
                padding-left: 26px;
                li{
                    margin-bottom: 7px;
                    a{
                        span{
                            text-decoration: none;
                            font-size: 14px;
                            font-weight: normal;
                        }
                        b{
                            font-size: 12px;
                        }
                        &:hover{
                            text-decoration: underline;
                        }
                    }
                }
            }
        }
    }
}
.inner-page3 .title-inner{
    margin-bottom: 33px;
}
.inner-page3 .bread-crumbs{
    margin-bottom: 25px;
}
.inner-page3 .title2{
    margin-bottom: 30px;
}
.selected-customer{
    background-color: #fff;
    margin-bottom: 20px;
    padding: 21px 0 0;
    .title-selected-customer{
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 27px;
        span{
            display: flex;
            align-items: center;
            background: url(../img/icon57.png) no-repeat 0 50%;
            min-height: 29px;
            padding-left: 40px;
            color: #b43709;
            font-size: 18px;
            font-weight: bold;
        }
    }
    ul{
        display: flex;
        flex-wrap: wrap;
        padding: 0 10px 10px;
        li{
            width: 25%;
            padding: 0 4px;
            margin-bottom: 20px;
            position: relative;
            .instruments-dop{
                position: absolute;
                top:60px;
                left: 0;
                background-color: #F4E8E3;
                border: 1px solid #49342D;
                display: none;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                padding: 10px 12px;
                &:after{
                    @include after;
                    position: absolute;
                    top: -8px;
                    left: 26px;
                    background: url(../img/icon111.png) no-repeat;
                    width: 8px;
                    height: 9px;
                }
                p{
                    display: block;
                    text-align: center;
                    font-size: 12px;
                    white-space: nowrap;
                    line-height: 14px;
                    color: #45332c;
                }
            }
            &:hover .instruments-dop{
                display: flex;
            }
            &:hover{
                z-index: 3;
            }
            img{
                display: block;
                margin: 0 auto;
                -webkit-border-radius: 100px;
                -moz-border-radius: 100px;
                border-radius: 100px;
                -webkit-box-shadow: 0 1px 4px rgba(0, 0, 0, 0.4);
                -moz-box-shadow: 0 1px 4px rgba(0, 0, 0, 0.4);
                box-shadow: 0 1px 4px rgba(0, 0, 0, 0.4);
                width: 100%;
                height:auto;
            }
        }
    }
}
.portfolio-master{
    margin-bottom: 24px;
    span{
        display: block;
        text-align: center;
        padding: 0 10px;
        color: #45332c;
        font-size: 16px;
        font-weight: bold;
        margin-bottom: 15px;
    }
    ul{
        li{
            margin-bottom: 6px;
            a{
                display: block;
                text-decoration: none;
                height:45px;
                line-height: 45px;
                padding-left: 55px;
                color: #fff;
                font-size: 13px;
                background-color: #b43709;
                -webkit-box-shadow: 0 2px 2px rgba(0,0,0, 0.4);
                -moz-box-shadow: 0 2px 2px rgba(0,0,0, 0.4);
                box-shadow: 0 2px 2px rgba(0,0,0, 0.4);
                @include transition;
                &:hover{
                    @include transition;
                    background-color: #c34618;
                }
            }
            &:nth-child(1) a{
                background: url(../img/icon58.png) no-repeat 0 50% #b43709;
                &:hover{
                    background: url(../img/icon58.png) no-repeat 0 50% #c34618;
                }
            }
            &:nth-child(2) a{
                background: url(../img/icon59.png) no-repeat 0 50% #b43709;
                &:hover{
                    background: url(../img/icon59.png) no-repeat 0 50% #c34618;
                }
            }
            &:nth-child(3) a{
                background: url(../img/icon60.png) no-repeat 0 50% #b43709;
                &:hover{
                    background: url(../img/icon60.png) no-repeat 0 50% #c34618;
                }
            }
        }
    }
}
.portfolio-master2{
    margin-bottom: 9px;
    ul{
        li{
            a{
                background: #b43709 !important;
                text-transform: uppercase;
                font-size: 14px;
                font-weight: bold;
                padding-left: 18px;
                &:hover{
                    background: #c34618 !important;
                }
            }
        }
    }
}
.my-messages{
    margin-bottom: 6px;
    a{
        display: flex;
        align-items: center;
        position: relative;
        padding-left: 45px;
        color: #fff;
        font-size: 14px;
        height:45px;
        text-decoration: none;
        -webkit-box-shadow: 0 2px 2px rgba(0,0,0, 0.4);
        -moz-box-shadow: 0 2px 2px rgba(0,0,0, 0.4);
        box-shadow: 0 2px 2px rgba(0,0,0, 0.4);
        background: url(../img/icon65.png) no-repeat 9px 48% #45332c;
        @include transition;
        &:hover{
            @include transition;
            background: url(../img/icon65.png) no-repeat 9px 48% #683e2e;
        }
        span{
            position: absolute;
            top:8px;
            right: 9px;
            font-size: 16px;
            display: block;
            color: #fff;
            text-align: center;
            width: 29px;
            height:29px;
            line-height: 29px;
            background-color: #53b412;
            -webkit-border-radius: 4px;
            -moz-border-radius: 4px;
            border-radius: 4px;
        }
    }
    &:nth-child(2) a{
        background: url(../img/icon108.png) no-repeat 10px 48% #45332c;
        @include transition;
        &:hover{
            @include transition;
            background: url(../img/icon108.png) no-repeat 10px 48% #683e2e;
        }
    }
    &:nth-child(3) a{
        background: url(../img/icon109.png) no-repeat 12px 48% #45332c;
        @include transition;
        &:hover{
            @include transition;
            background: url(../img/icon109.png) no-repeat 12px 48% #683e2e;
        }
    }
}
.my-messages2{
    a{
        background: url(../img/icon110.png) no-repeat 12px 48% #45332c;
        &:hover{
            @include transition;
            background: url(../img/icon110.png) no-repeat 12px 48% #683e2e;
        }
    }
}
.my-messages3{
    a{
        background: url(../img/icon62.png) no-repeat 12px 48% #45332c;
        &:hover{
            @include transition;
            background: url(../img/icon62.png) no-repeat 12px 48% #683e2e;
        }
    }
}
.dop-block5{
    border: 1px solid #49342D;
    background-color: #fff;
    margin-top: 11px;
    padding: 13px 9px 11px 9px;
    position: relative;
    margin-bottom:15px;
    &:after{
        @include after;
        position: absolute;
        top: -8px;
        left: 17px;
        background: url(../img/icon61.png) no-repeat;
        width: 8px;
        height:10px;
    }
    span{
        display: block;
        text-align: center;
        color: #45332c;
        font-size: 14px;
        margin-bottom: 12px;
    }
    ul{
        display: flex;
        flex-wrap: wrap;
        li{
            width: 25%;
            padding: 0 4px;
            position: relative;
            z-index: 7;
            .instruments-dop{
                position: absolute;
                top:60px;
                left: 0;
                background-color: #F4E8E3;
                border: 1px solid #49342D;
                display: none;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                padding: 10px 12px;
                &:after{
                    @include after;
                    position: absolute;
                    top: -8px;
                    left: 26px;
                    background: url(../img/icon111.png) no-repeat;
                    width: 8px;
                    height: 9px;
                }
                p{
                    display: block;
                    text-align: center;
                    font-size: 12px;
                    white-space: nowrap;
                    line-height: 14px;
                    color: #45332c;
                }
            }
            &:hover .instruments-dop{
                display: flex;
            }
            &:hover{
                z-index: 8;
            }
            img{
                display: block;
                margin: 0 auto;
                -webkit-border-radius: 100px;
                -moz-border-radius: 100px;
                border-radius: 100px;
                -webkit-box-shadow: 0 1px 4px rgba(0, 0, 0, 0.4);
                -moz-box-shadow: 0 1px 4px rgba(0, 0, 0, 0.4);
                box-shadow: 0 1px 4px rgba(0, 0, 0, 0.4);
                width: 100%;
                height: auto;
            }
        }
    }
}
.dop-block7{
    padding:13px 15px 11px 15px;
    img{
        display: block;
        width: 100%;
        height:auto;
    }
}
.dop-block6{
    display: flex;
    justify-content: space-between;
    padding: 10px 28px;
    flex-wrap: nowrap;
    align-items: center;
    span{
        display: block;
        text-align: left;
        color: #45332c;
        font-size: 14px;
        line-height: 16px;
        margin-bottom: 0;
        strong{
            font-weight: bold;
        }
    }
    img{
        display: block;
        width: auto;
        height:auto;
    }
}
.my-messages-container{
    padding:10px 18px 30px 18px;
}
.my-messages-block{
    display: flex;
    flex-wrap: nowrap;
    margin-bottom: 33px;
    justify-content: space-between;
    .foto-user{
        min-width: 55px;
        margin-right: 31px;
        width: 55px;
        img{
            display: block;
            border: 1px solid #45332c;
            -webkit-border-radius: 100px;
            -moz-border-radius: 100px;
            border-radius: 100px;
            width: 100%;
            height: auto;
        }
    }
    .sms{
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 100%;
        padding:13px 27px 13px 40px;
        background-color: #f1e8e5;
        &:after{
            content: '';
            display: block;
            clear: both;
            position: absolute;
            top: 8px;
            left: -17px;
            background: url(../img/icon51.png) no-repeat;
            width: 19px;
            height: 37px;
        }
        span{
            display: block;
            font-weight: bold;
            font-size: 16px;
            color: #b43709;
            margin-bottom: 3px;
        }
        strong{
            display: block;
            font-weight: bold;
            font-size: 14px;
            color: #45332c;
            margin-bottom: 7px;
        }
        p{
            display: block;
            font-size: 14px;
            color: #352a26;
            line-height: 16px;
            margin-bottom: 17px;
            &:last-child{
                margin: 0;
            }
        }
    }
}
.page-navigation{
    ul{
        display: flex;
        justify-content: flex-end;
        li{
            margin-left: 6px;
            a{
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 16px;
                font-weight: 900;
                color: #352a26;
                -webkit-border-radius: 100px;
                -moz-border-radius: 100px;
                border-radius: 100px;
                width: 35px;
                height:35px;
                border: 2px solid #352a26;
                text-decoration: none;
                @include transition;
                &:hover{
                    @include transition;
                    border: 2px solid #b43709;
                }
            }
            &.active a{
                border: 2px solid #b43709;
            }
            span{
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 16px;
                font-weight: 900;
                color: #352a26;
                -webkit-border-radius: 100px;
                -moz-border-radius: 100px;
                border-radius: 100px;
                width: 35px;
                height:35px;
                border: 2px solid #352a26;
                text-decoration: none;
            }
        }
    }
}
a.back{
    color: #352a26;
    display: block;
    font-size: 14px;
    text-decoration: none;
    padding-left: 13px;
    background: url(../img/bg-a.png) no-repeat 0 30%;
    &:hover{
        text-decoration: underline;
    }
}
.my-messages-block2{
    .foto-user{
        order: 2;
        margin: 0 0 0 31px;
    }
    .sms:after{
        left: auto;
        right: -17px;
        background: url(../img/icon52.png) no-repeat 100% 0;
    }
}
.recommendation-master5{
    padding-top: 5px;
    padding-left: 84px;
    padding-bottom: 30px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    .left{
        display: flex;
        margin-bottom: 30px;
        .foto-master{
            -webkit-border-radius: 100px;
            -moz-border-radius: 100px;
            border-radius: 100px;
            width: 82px;
            height: 82px;
            margin-right: 10px;
            img{
                display: block;
                border: 1px solid #45332c;
                -webkit-border-radius: 100px;
                -moz-border-radius: 100px;
                border-radius: 100px;
                width: 100%;
                height: auto;
            }
        }
        .info-master{
            padding-top: 7px;
            span{
                display: block;
                text-decoration: underline;
                color: #352a26;
                font-size: 14px;
                margin-bottom: 5px;
            }
            b{
                display: block;
                text-transform: uppercase;
                color: #b43709;
                font-size: 12px;
                margin-bottom: 9px;
            }
            a{
                display: block;
                text-align: center;
                -webkit-border-radius: 3px;
                -moz-border-radius: 3px;
                border-radius: 3px;
                font-weight: 500;
                color: #fff;
                text-decoration: none;
                font-size: 12px;
                text-transform: uppercase;
                width: 93px;
                height: 29px;
                line-height: 31px;
                background-color: #53b412;
                -webkit-box-shadow: 0 2px 2px rgba(0, 1, 1, 0.4);
                -moz-box-shadow: 0 2px 2px rgba(0, 1, 1, 0.4);
                box-shadow: 0 2px 2px rgba(0, 1, 1, 0.4);
                -webkit-transition: 0.4s;
                -moz-transition: 0.4s;
                -ms-transition: 0.4s;
                -o-transition: 0.4s;
                transition: 0.4s;
                margin: 0;
                &:hover{
                    @include transition;
                    background-color: #5bc017;
                }
            }
        }
    }
    .right{
        margin-bottom: 30px;
        padding-left: 96px;
        position: relative;
        &:after{
            @include after;
            position: absolute;
            top: 0px;
            left: 0;
            width: 69px;
            height:67px;
            background: url(../img/img11.jpg) no-repeat;
            -webkit-background-size: 100% 100%;
            background-size: 100% 100%;
        }
        ul{
            min-width: 210px;
            li{
                display: flex;
                justify-content: space-between;
                margin-bottom: 7px;
                span{
                    display: block;
                    color: #b43709;
                    font-size: 14px;
                    font-weight: bold;
                }
                strong{
                    display: block;
                    color: #352a26;
                    font-size: 16px;
                    font-weight: bold;
                    text-align: left;
                    min-width: 30px;
                }
                a{
                    display: block;
                    color: #352a26;
                    font-size: 14px;
                    text-decoration: underline;
                    &:hover{
                        text-decoration: none;
                    }
                }
            }
            li:first-child strong{
                color: #b43709;
            }
            li:nth-child(2) a{
                padding-right: 26px;
                background: url(../img/icon66.png) no-repeat 100% 50%;
                min-height: 19px;
                line-height: 19px;
            }
        }
    }
}
.title-recommendation-master5{
    display: block;
    font-size: 15px;
    color: #b43709;
    font-weight: bold;
    margin-bottom: 23px;
    padding-left: 40px;
    width: 100%;
}
.photos{
    width: 100%;
    background: url(../img/icon67.png) no-repeat 0 7px;
    padding-left: 30px;
    ul{
        display: flex;
        flex-wrap: wrap;
        li{
            margin-right: 6px;
            margin-bottom: 6px;
            a{
                display: block;
                img{
                    display: block;
                }
            }
        }
    }
}
.my-messages-container2{
    .my-messages-block{
        margin-bottom: 12px;
        .photos2{
            min-width: 55px;
            margin-right: 31px;
            width: 55px;
            .foto-user{
                position: relative;
                min-width: 1px;
                width: 100%;
                margin: 0 0 20px 0;
                z-index:2;
                &:after{
                    @include after;
                    position: absolute;
                    top:50px;
                    left: 50%;
                    background: url(../img/icon68.png) no-repeat;
                    width: 23px;
                    height:33px;
                    margin-left: -11.5px;
                    z-index: -1;
                }
            }
            .foto-user2{
                position: relative;
                z-index:2;
                width: 41px;
                margin: 0 auto 0;
                &:after{
                    display: none;
                }
            }
        }
    }
    .recommendation-master5{
        margin-bottom: 47px;
        padding-top: 0;
        padding-bottom: 0;
    }
}
a.btn-question{
    margin: 0 0 50px 86px;
    display: block;
    text-decoration: none;
    text-align: center;
    width: 262px;
    height:40px;
    background-color: #b43709;
    -webkit-box-shadow: 0 2px 2px rgba(0, 0, 0, 0.4);
    -moz-box-shadow: 0 2px 2px rgba(0, 0, 0, 0.4);
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.4);
    line-height: 40px;
    color: #fff;
    @include transition;
    font-size: 14px;
    &:hover{
        @include transition;
        background-color: #c34618;
    }
}
.reviews-container{
    .top{
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
        .left{
            display: flex;
            align-items: center;
            margin-bottom: 15px;
            .foto{
                width: 70px;
                height:70px;
                -webkit-border-radius: 100px;
                -moz-border-radius: 100px;
                border-radius: 100px;
                margin-right: 17px;
                img{
                    display: block;
                    width: 100%;
                    height:auto;
                    border: 1px solid #45332c;
                    -webkit-border-radius: 100px;
                    -moz-border-radius: 100px;
                    border-radius: 100px;
                }
            }
            .name{
                span{
                    display: block;
                    font-weight: bold;
                    color: #b43709;
                    font-size: 16px;
                    margin-bottom: 5px;
                }
                a{
                    display: block;
                    font-weight: bold;
                    color: #352a26;
                    font-size: 16px;
                    text-decoration: underline;
                    &:hover{
                        text-decoration: none;
                    }
                }
            }
        }
        .right{
            margin-bottom: 15px;
            display: flex;
            align-items: center;
            a{
                display: block;
                text-decoration: underline;
                color: #352a26;
                font-size: 14px;
                line-height: 16px;
                margin-right: 25px;
                &:hover{
                    text-decoration: none;
                }
            }
            .schedule-block{
                margin-right: 25px;
                img{
                    display: block;
                    width: 69px;
                    height: 67px;
                }
            }
            .iconka{
                img{
                    display: block;
                }
            }
        }
    }
    .bottom{
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        .left{
            width: 49%;
            margin-bottom: 30px;
            .review{
                background-color: #f1e8e5;
                padding: 20px 35px 15px 22px;
                position: relative;
                &:after{
                    @include after;
                    position: absolute;
                    top:10px;
                    right: 12px;
                    background: url(../img/icon69.png) no-repeat;
                    width: 15px;
                    height:38px;
                }
                span{
                    display: block;
                    font-weight: bold;
                    color: #ba2427;
                    font-size: 15px;
                    margin-bottom: 20px;
                }
                ul{
                    li{
                        background: url(../img/bg-li3.png) no-repeat 0 6px;
                        line-height: 18px;
                        color: #352a26;
                        font-size: 14px;
                        padding-left: 10px;
                    }
                }
            }
        }
        .right{
            width: 49%;
            margin-bottom: 30px;
            .review{
                background-color: #f1e8e5;
                padding: 20px 35px 15px 22px;
                position: relative;
                &:after{
                    @include after;
                    position: absolute;
                    top:10px;
                    right: 12px;
                    background: url(../img/icon70.png) no-repeat;
                    width: 15px;
                    height:38px;
                }
                span{
                    display: block;
                    font-weight: bold;
                    color: #53b412;
                    font-size: 15px;
                    margin-bottom: 20px;
                }
                ul{
                    li{
                        background: url(../img/bg-li3.png) no-repeat 0 6px;
                        color: #352a26;
                        padding-left: 10px;
                        font-size: 14px;
                        line-height: 18px;
                    }
                }
            }
        }
    }
}
.reviews-container2{
    .top{
        flex-wrap: nowrap;
        align-items: flex-start;
        .left{
            align-items: flex-start;
            min-width: 250px;
            margin-bottom: 40px;
        }
        .center{
            max-width: 203px;
            margin-bottom: 40px;
            img{
                width: 100%;
                height:auto;
            }
        }
        .right2{
            margin-bottom: 40px;
            display: flex;
            min-width: 240px;
            justify-content: flex-end;
            .foto{
                width: 56px;
                height:56px;
                -webkit-border-radius: 100px;
                -moz-border-radius: 100px;
                border-radius: 100px;
                margin-right: 29px;
                img{
                    display: block;
                    width: 100%;
                    height:auto;
                    border: 1px solid #45332c;
                    -webkit-border-radius: 100px;
                    -moz-border-radius: 100px;
                    border-radius: 100px;
                }
            }
            .rating{
                .rating-block{
                    margin-bottom: 8px;
                    position: relative;
                    width: 137px;
                    background-color: #f1e8e5;
                    padding: 18px;
                    &:after{
                        @include after;
                        position: absolute;
                        top:18px;
                        left: -17px;
                        background: url(../img/icon51.png) no-repeat 0 50%;
                        width: 21px;
                        height: 23px;
                    }
                    img{
                        display: block;
                        width: 100%;
                        height:auto;
                    }
                }
                a{
                    display: block;
                    text-decoration: underline;
                    font-size: 12px;
                    color: #352a26;
                    line-height: 14px;
                    &:hover{
                        text-decoration: none;
                    }
                }
            }
        }
    }
}
.customer-orders{
    margin-bottom: 25px;
    .top{
        display: flex;
        flex-wrap: nowrap;
        margin-bottom: 20px;
        .foto{
            width: 56px;
            height: 56px;
            min-width: 56px;
            -webkit-border-radius: 100px;
            -moz-border-radius: 100px;
            border-radius: 100px;
            margin-right: 30px;
            position: relative;
            z-index: 3;
            a{
                display: block;
            }
            img{
                display: block;
                width: 100%;
                height:auto;
                border: 1px solid #45332c;
                -webkit-border-radius: 100px;
                -moz-border-radius: 100px;
                border-radius: 100px;
            }
            .instruments-dop{
                position: absolute;
                top:70px;
                left: 0;
                background-color: #F4E8E3;
                border: 1px solid #49342D;
                display: none;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                padding: 10px 12px;
                &:after{
                    @include after;
                    position: absolute;
                    top: -8px;
                    left: 22px;
                    background: url(../img/icon111.png) no-repeat;
                    width: 8px;
                    height: 9px;
                }
                p{
                    display: block;
                    text-align: center;
                    font-size: 12px;
                    white-space: nowrap;
                    line-height: 14px;
                    color: #45332c;
                }
            }
            &:hover .instruments-dop{
                display: flex;
            }
        }
        .right{
            position: relative;
            width: 100%;
            background-color: #f1e8e5;
            &:after{
                @include after;
                position: absolute;
                top:18px;
                left: -18px;
                background: url(../img/icon51.png) no-repeat 0 50%;
                width: 22px;
                height: 23px;
            }
            b{
                display: block;
                padding-left: 60px;
                background: url(../img/marker.png) no-repeat 30px 50%;
                min-height: 23px;
                line-height: 16px;
                padding-top: 5px;
                color: #352a26;
                font-size: 14px;
                margin-bottom: 8px;
                padding-right: 10px;
            }
            .top-block{
                padding: 10px 15px 11px 30px;
                border-bottom: 1px solid #392C29;
                margin-bottom: 6px;
                p{
                    display: block;
                    font-size: 14px;
                    line-height: 16px;
                    color: #352a26;
                }
                .description{
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: space-between;
                    .left{
                        display: block;
                        margin-bottom: 14px;
                        span{
                            display: block;
                            color: #352a26;
                            font-size: 11px;
                            margin-bottom: 4px;
                        }
                        strong{
                            display: block;
                            font-weight: bold;
                            color: #53b412;
                            font-size: 16px;
                            margin-bottom: 6px;
                        }
                        a{
                            display: block;
                            text-decoration: underline;
                            font-weight: bold;
                            color: #352a26;
                            font-size: 16px;
                            &:hover{
                                text-decoration: none;
                            }
                        }
                    }
                    .right{
                        display: block;
                        background-color: transparent;
                        width: auto;
                        margin-bottom: 14px;
                        &:after{
                            display: none;
                        }
                        a{
                            display: block;
                            text-align: center;
                            color: #fff;
                            font-weight: 900;
                            font-size: 12px;
                            text-transform: uppercase;
                            text-decoration: none;
                            background-color: #53b412;
                            width: 127px;
                            height:30px;
                            line-height: 30px;
                            @include transition;
                            margin-bottom: 8px;
                            &:hover{
                                @include transition;
                                background-color: #5bc017;
                            }
                        }
                        span{
                            display: block;
                            text-align: center;
                            font-size: 12px;
                            color: #352a26;
                            font-weight: 500;
                        }
                    }
                }
            }
        }
    }
    .center{
        margin: 0 0 15px 0;
        ul{
            display: flex;
            flex-wrap: wrap;
            justify-content: flex-end;
            li{
                display: flex;
                flex-wrap: nowrap;
                align-items: center;
                margin-left: 22px;
                margin-bottom: 20px;
                width: 140px;
                position: relative;
                z-index: 1;
                &:hover{
                    z-index: 2;
                }
                &:first-child{
                    margin-left: 0;
                }
                .smile{
                    width: 61px;
                    min-width: 61px;
                    margin-right: 20px;
                    .smile-block{
                        height:47px;
                        background-color: #f1e8e5;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        position: relative;
                        z-index: 5;
                        &:after{
                            @include after;
                            position: absolute;
                            top:7px;
                            right: -13px;
                            background: url(../img/icon52.png) no-repeat 100% 50%;
                            width: 15px;
                            height: 15px;
                        }
                        .instruments-dop{
                            position: absolute;
                            top:60px;
                            left: 0;
                            background-color: #F4E8E3;
                            border: 1px solid #49342D;
                            display: none;
                            flex-direction: column;
                            justify-content: center;
                            align-items: center;
                            padding: 10px 12px;
                            &:after{
                                @include after;
                                position: absolute;
                                top: -8px;
                                left: 26px;
                                background: url(../img/icon111.png) no-repeat;
                                width: 8px;
                                height: 9px;
                            }
                            p{
                                display: block;
                                text-align: center;
                                font-size: 12px;
                                white-space: nowrap;
                                line-height: 14px;
                                color: #45332c;
                            }
                        }
                        &:hover .instruments-dop{
                            display: flex;
                        }
                    }
                }
                .foto{
                    width: 56px;
                    height:56px;
                    min-width: 56px;
                    -webkit-border-radius: 100px;
                    -moz-border-radius: 100px;
                    border-radius: 100px;
                    margin-right: 30px;
                    position: relative;
                    z-index: 5;
                    .instruments-dop{
                        position: absolute;
                        top: 70px;
                        left: 0;
                        background-color: #F4E8E3;
                        border: 1px solid #49342D;
                        display: none;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;
                        padding: 10px 12px;
                        &:after{
                            @include after;
                            position: absolute;
                            top: -8px;
                            left: 22px;
                            background: url(../img/icon111.png) no-repeat;
                            width: 8px;
                            height: 9px;
                        }
                        p{
                            display: block;
                            text-align: center;
                            font-size: 12px;
                            white-space: nowrap;
                            line-height: 14px;
                            color: #45332c;
                        }
                    }
                    &:hover .instruments-dop{
                        display: flex;
                    }
                    img{
                        display: block;
                        width: 100%;
                        height:auto;
                        border: 1px solid #45332c;
                        -webkit-border-radius: 100px;
                        -moz-border-radius: 100px;
                        border-radius: 100px;
                    }
                }
            }
        }
    }
    .bottom{
        padding-left: 85px;
        b{
            display: block;
            font-weight: bold;
            color: #b43709;
            font-size: 14px;
            margin-bottom: 13px;
        }
        .master{
            display: flex;
            flex-wrap: nowrap;
            align-items: center;
            .foto{
                width: 56px;
                height:56px;
                min-width: 56px;
                -webkit-border-radius: 100px;
                -moz-border-radius: 100px;
                border-radius: 100px;
                margin-right: 12px;
                img{
                    display: block;
                    width: 100%;
                    height:auto;
                    border: 1px solid #45332c;
                    -webkit-border-radius: 100px;
                    -moz-border-radius: 100px;
                    border-radius: 100px;
                }
            }
            .info-master{
                display: flex;
                flex-direction: column;
                justify-content: center;
                a{
                    display: block;
                    text-decoration: underline;
                    color: #352a26;
                    font-size: 14px;
                    margin-bottom: 5px;
                    &:hover{
                        text-decoration: none;
                    }
                }
                span{
                    display: block;
                    color: #b43709;
                    font-size: 12px;
                    text-transform: uppercase;
                }
            }
        }
    }
}
.add-order{
    margin-bottom: 16px;
    a{
        display: block;
        padding-left: 47px;
        font-size: 14px;
        color: #fff;
        @include transition;
        text-decoration: none;
        height:45px;
        line-height: 47px;
        background: url(../img/icon76.png) no-repeat 13px 50% #45332c;
        -webkit-box-shadow: 0 2px 2px rgba(0,0,0, 0.4);
        -moz-box-shadow: 0 2px 2px rgba(0,0,0, 0.4);
        box-shadow: 0 2px 2px rgba(0,0,0, 0.4);
        &:hover{
            @include transition;
            background: url(../img/icon76.png) no-repeat 13px 50% #683e2e;
        }
    }
}
.price-block{
    margin-bottom: 18px;
    .price-btn{
        cursor: pointer;
        position: relative;
        padding-left: 25px;
        background-color: #45332c;
        -webkit-box-shadow: 0 2px 2px rgba(0, 1, 1, 0.4);
        -moz-box-shadow: 0 2px 2px rgba(0, 1, 1, 0.4);
        box-shadow: 0 2px 2px rgba(0, 1, 1, 0.4);
        height: 45px;
        line-height: 47px;
        width: 100%;
        color: #fff;
        font-size: 14px;
        text-transform: uppercase;
        &:after{
            content: '';
            display: block;
            clear: both;
            position: absolute;
            top: 50%;
            right: 15px;
            background: url(../img/icon53.png) no-repeat;
            width: 16px;
            height: 11px;
            -webkit-transform: translate(0, -50%);
            -moz-transform: translate(0, -50%);
            -ms-transform: translate(0, -50%);
            -o-transform: translate(0, -50%);
            transform: translate(0, -50%);
        }
    }
    .price-dop{
        display: block;
        background-color: #fff;
        border: 1px solid #45332c;
        border-top: none;
        padding: 18px;
        .check{
            display: flex;
            justify-content: center;
            input[type="checkbox"] {
                display: none;
            }
            input[type="checkbox"]+label {
                cursor:pointer;
                color:#45332c;
                font-family: 'Roboto', sans-serif;
                font-size: 14px;
                display:block;
                padding: 3px 0 0 28px;
                background: url(../img/check.png) no-repeat 0 50%;
                min-height: 19px;
            }
            input[type="checkbox"]:checked + label {
                background: url(../img/check2.png) no-repeat 0 50%;
            }
        }
    }
}
.order-type-block{
    margin-bottom: 18px;
    .order-type-btn{
        cursor: pointer;
        position: relative;
        padding-left: 25px;
        background-color: #45332c;
        -webkit-box-shadow: 0 2px 2px rgba(0, 1, 1, 0.4);
        -moz-box-shadow: 0 2px 2px rgba(0, 1, 1, 0.4);
        box-shadow: 0 2px 2px rgba(0, 1, 1, 0.4);
        height: 45px;
        line-height: 47px;
        width: 100%;
        color: #fff;
        font-size: 14px;
        text-transform: uppercase;
        &:after{
            content: '';
            display: block;
            clear: both;
            position: absolute;
            top: 50%;
            right: 15px;
            background: url(../img/icon53.png) no-repeat;
            width: 16px;
            height: 11px;
            -webkit-transform: translate(0, -50%);
            -moz-transform: translate(0, -50%);
            -ms-transform: translate(0, -50%);
            -o-transform: translate(0, -50%);
            transform: translate(0, -50%);
        }
    }
    .order-type-dop{
        display: block;
        background-color: #fff;
        border: 1px solid #45332c;
        border-top: none;
        padding:25px 10px 22px 10px;
        ul{
            li{
                margin-bottom: 6px;
                input[type="checkbox"] {
                    display: none;
                }
                input[type="checkbox"]+label {
                    cursor:pointer;
                    color:#45332c;
                    font-family: 'Roboto', sans-serif;
                    font-size: 14px;
                    display:block;
                    padding: 5px 0 0 28px;
                    background: url(../img/check.png) no-repeat 0 50%;
                    min-height: 19px;
                }
                input[type="checkbox"]:checked + label {
                    background: url(../img/check2.png) no-repeat 0 50%;
                }
            }
        }
    }
}
.clear{
    display: block;
    margin: 0 auto 35px;
    font-family: 'Roboto', sans-serif;
    font-size: 14px;
    color: #fff;
    border: none;
    cursor: pointer;
    width: 166px;
    height:40px;
    -webkit-box-shadow: 0 2px 2px rgba(0, 0, 0, 0.4);
    -moz-box-shadow: 0 2px 2px rgba(0, 0, 0, 0.4);
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.4);
    @include transition;
    background-color: #b43709;
    &:hover{
        @include transition;
        background-color: #c34618;
    }
}
.ui-slider-handle {
    width: 32px !important;
    height:32px !important;
    background-color: #53b412 !important;
    -webkit-border-radius: 100px !important;
    -moz-border-radius: 100px !important;
    border-radius: 100px !important;
    top: -14px !important;
    border: none !important;
    &:hover{
        background-color: #5bc017 !important;
    }
    &:active{
        background-color: #5bc017 !important;
    }
}
.ui-state-hover{
    background-color: #5bc017 !important;
    border: none !important;
}
.ui-state-active{
    background-color: #5bc017 !important;
    border: none !important;
}
.ui-state-focus{
    background-color: #5bc017 !important;
    border: none !important;
}
.ui-widget.ui-widget-content {
    border: 1px solid #49342D !important;
    height:6px;
    background-color: #fff;
    margin-bottom: 28px;
}
#slider-range span:last-child{
    margin-left: -24px !important;
}
.price-block .parent-active {
    margin-bottom: 18px;
    input[type="text"] {
        width: 100%;
        text-align: center;
        display: block;
        color: #45332c !important;
        font-weight: normal !important;
        font-family: 'Roboto', sans-serif;
        font-size: 14px;
    }

}
.main-container2{
    margin-top: 62px;
    position: relative;
}
.search-form{
    position: absolute;
    top:-62px;
    left: 0;
    width: 100%;
    form{
        display: flex;
        input[type="text"] {
            display: block;
            width: 100%;
            border: 1px solid #392C29;
            background-color: #fff;
            padding: 0 20px;
            color: #45332c;
            font-size: 14px;
            font-family: 'Roboto', sans-serif;
            border-right: none;
            height:45px;
            text-align: left;
        }
        input[type="submit"] {
            display: block;
            height:45px;
            border: none;
            cursor: pointer;
            color: #fff;
            font-size: 16px;
            font-weight: 500;
            padding-left: 57px;
            font-family: 'Roboto', sans-serif;
            background: url(../img/lupa.png) no-repeat 28px 50% #b43709;
            text-align: left;
            @include transition;
            min-width: 140px;
            &:hover{
                background: url(../img/lupa.png) no-repeat 28px 50% #c34618;
            }
        }
    }
}
.customer-block5{
    display: flex;
    justify-content: space-between;
    margin-bottom: 30px;
    .foto{
        width: 90px;
        min-width: 90px;
        -webkit-border-radius: 100px;
        -moz-border-radius: 100px;
        border-radius: 100px;
        margin-right: 26px;
        position: relative;
        z-index: 5;
        a{
            display: block;
            position: relative;
            .instruments-dop {
                position: absolute;
                top: 120px;
                left: 0;
                background-color: #F4E8E3;
                border: 1px solid #49342D;
                display: none;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                padding: 10px 12px;
                display: none;
                &:after{
                    content: '';
                    display: block;
                    clear: both;
                    position: absolute;
                    top: -8px;
                    left: 39px;
                    background: url(../img/icon111.png) no-repeat;
                    width: 8px;
                    height: 9px;
                }
                p{
                    display: block;
                    text-align: center;
                    font-size: 12px;
                    white-space: nowrap;
                    line-height: 14px;
                    color: #45332c;
                }
            }
            &:hover .instruments-dop{
                display: flex;
            }
        }
        .img{
            display: block;
            width: 100%;
            height:auto;
        }
    }
    .customer-container{
        position: relative;
        width: 100%;
        background-color: #f1e8e5;
        &:after{
            @include after;
            position: absolute;
            top: 18px;
            left: -18px;
            background: url(../img/icon51.png) no-repeat 0 50%;
            width: 22px;
            height: 23px;
        }
        .top{
            padding: 15px 15px 1px 25px;
            border-bottom: 1px solid #392C29;
            .top{
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;
                padding: 0;
                border: none;
                margin: 0;
                .left{
                    align-items: flex-start;
                    a{
                        display: block;
                        text-decoration: underline;
                        font-size: 14px;
                        color: #352a26;
                        margin-bottom: 6px;
                        &:hover{
                            text-decoration: none;
                        }
                    }
                    span{
                        display: block;
                        font-size: 14px;
                        color: #b43709;
                        margin-bottom: 6px;
                        b{
                            font-weight: bold;
                        }
                    }
                    ul{
                        display: flex;
                        flex-wrap: wrap;
                        li{
                            margin-right: 7px;
                            margin-bottom: 7px;
                            .foto-master{
                                -webkit-border-radius: 100px;
                                -moz-border-radius: 100px;
                                border-radius: 100px;
                                width: 46px;
                                height: 46px;
                                -webkit-box-shadow: 0 1px 4px rgba(0, 0, 0, 0.4);
                                -moz-box-shadow: 0 1px 4px rgba(0, 0, 0, 0.4);
                                box-shadow: 0 1px 4px rgba(0, 0, 0, 0.4);
                                position: relative;
                                img{
                                    display: block;
                                    width: 100%;
                                    height:auto;
                                }
                                .instruments-dop {
                                    position: absolute;
                                    top: 59px;
                                    left: 0;
                                    background-color: #F4E8E3;
                                    border: 1px solid #49342D;
                                    display: none;
                                    flex-direction: column;
                                    justify-content: center;
                                    align-items: center;
                                    padding: 10px 12px;
                                    display: none;
                                    z-index: 3;
                                    &:after{

                                        content: '';
                                        display: block;
                                        clear: both;
                                        position: absolute;
                                        top: -8px;
                                        left: 19px;
                                        background: url(../img/icon111.png) no-repeat;
                                        width: 8px;
                                        height: 9px;
                                    }
                                    p{
                                        display: block;
                                        text-align: center;
                                        font-size: 12px;
                                        white-space: nowrap;
                                        line-height: 14px;
                                        color: #45332c;
                                    }
                                }
                                &:hover .instruments-dop{
                                    display: flex;
                                }
                            }
                        }
                    }
                }
                .center{
                    .center-block{
                        position: relative;
                        background-color: #d4d4d4;
                        padding: 10px 14px;
                        &:after{
                            @include after;
                            position: absolute;
                            top: 50%;
                            left: -7px;
                            margin-top: -4px;
                            width: 8px;
                            height:8px;
                            background: url(../img/icon79.png) no-repeat;
                        }
                        strong{
                            display: block;
                            font-weight: bold;
                            margin-bottom: 5px;
                            font-size: 12px;
                            color: #45332c;
                        }
                        em{
                            display: block;
                            font-style: italic;
                            font-size: 12px;
                            color: #45332c;
                        }
                    }
                }
                .right{
                    padding-top: 7px;
                    margin-bottom: 15px;
                    ul{
                        li{
                            display: flex;
                            justify-content: space-between;
                            margin-bottom: 4px;
                            align-items: center;
                            &:last-child{
                                margin: 0;
                            }
                            span{
                                display: block;
                                color: #b43709;
                                font-size: 14px;
                                font-weight: bold;
                            }
                            strong{
                                display: block;
                                color: #352a26;
                                font-size: 16px;
                                font-weight: bold;
                                text-align: left;
                                min-width: 30px;
                            }
                            a{
                                display: block;
                                color: #352a26;
                                font-size: 14px;
                                text-decoration: underline;
                                padding-right: 30px;
                                background: url(../img/icon666.png) no-repeat 100% 50%;
                                min-height: 24px;
                                line-height: 24px;
                                margin-right: 10px;
                                &:hover{
                                    text-decoration: none;
                                }
                            }
                            a.rec{
                                background: none;
                                padding-right: 0;
                            }
                        }
                    }
                }
            }
            .bottom{
                .left{
                    span{
                        position: relative;
                        &:after{
                            @include after;
                            position: absolute;
                            top: -2px;
                            left: -19px;
                            background: url(../img/icon77.png) no-repeat;
                            width: 15px;
                            height:14px;
                        }
                    }
                }
                .right{
                    padding: 0;
                    .schedule{
                        img{
                            display: block;
                            width: 91px;
                            height: 88px;
                        }
                    }
                }
            }
        }
        .bottom-block{
            padding: 8px 14px 8px 24px;
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            justify-content: space-between;
            ul{
                display: flex;
                li{
                    padding-right: 22px;
                    margin-right: 21px;
                    background: url(../img/bg-li4.png) no-repeat 100% 50%;
                    &:last-child{
                        padding: 0;
                        margin: 0;
                        background: none;
                    }
                    span{
                        display: block;
                        font-size: 14px;
                        color: #352a26;
                        b{
                            font-weight: bold;
                        }
                    }
                }
            }
            .right{
                background: url(../img/marker.png) no-repeat 0 50%;
                padding-left: 27px;
                min-height: 23px;
                line-height: 23px;
                color: #352a26;
                font-size: 14px;
            }
        }
    }
}
.customer-block6 .customer-container .top .bottom .left span {
    color: #352a26;
    &:after{
        background: url(../img/icon78.png) no-repeat;
    }
}
.experience{
    margin-bottom: 18px;
    .experience-btn{
        cursor: pointer;
        position: relative;
        padding-left: 25px;
        background-color: #45332c;
        -webkit-box-shadow: 0 2px 2px rgba(0, 1, 1, 0.4);
        -moz-box-shadow: 0 2px 2px rgba(0, 1, 1, 0.4);
        box-shadow: 0 2px 2px rgba(0, 1, 1, 0.4);
        height: 45px;
        line-height: 47px;
        width: 100%;
        color: #fff;
        font-size: 14px;
        text-transform: uppercase;
        &:after{
            content: '';
            display: block;
            clear: both;
            position: absolute;
            top: 50%;
            right: 15px;
            background: url(../img/icon53.png) no-repeat;
            width: 16px;
            height: 11px;
            -webkit-transform: translate(0, -50%);
            -moz-transform: translate(0, -50%);
            -ms-transform: translate(0, -50%);
            -o-transform: translate(0, -50%);
            transform: translate(0, -50%);
        }
    }
    .experience-dop{
        display: block;
        background-color: #fff;
        border: 1px solid #45332c;
        border-top: none;
        padding: 25px 10px 22px 10px;
        ul{
            li{
                margin-bottom: 6px;
                input[type="checkbox"] {
                    display: none;
                }
                input[type="checkbox"]+label {
                    cursor:pointer;
                    color:#45332c;
                    font-family: 'Roboto', sans-serif;
                    font-size: 14px;
                    display:block;
                    padding: 5px 0 0 28px;
                    background: url(../img/check.png) no-repeat 0 50%;
                    min-height: 19px;
                }
                input[type="checkbox"]:checked + label {
                    background: url(../img/check2.png) no-repeat 0 50%;
                }
            }
        }
    }
}
.additionally{
    margin-bottom: 18px;
    .additionally-btn{
        cursor: pointer;
        position: relative;
        padding-left: 25px;
        background-color: #45332c;
        -webkit-box-shadow: 0 2px 2px rgba(0, 1, 1, 0.4);
        -moz-box-shadow: 0 2px 2px rgba(0, 1, 1, 0.4);
        box-shadow: 0 2px 2px rgba(0, 1, 1, 0.4);
        height: 45px;
        line-height: 47px;
        width: 100%;
        color: #fff;
        font-size: 14px;
        text-transform: uppercase;
        &:after{
            content: '';
            display: block;
            clear: both;
            position: absolute;
            top: 50%;
            right: 15px;
            background: url(../img/icon53.png) no-repeat;
            width: 16px;
            height: 11px;
            -webkit-transform: translate(0, -50%);
            -moz-transform: translate(0, -50%);
            -ms-transform: translate(0, -50%);
            -o-transform: translate(0, -50%);
            transform: translate(0, -50%);
        }
    }
    .additionally-dop{
        display: block;
        background-color: #fff;
        border: 1px solid #45332c;
        border-top: none;
        padding: 25px 10px 22px 10px;
        ul{
            li{
                margin-bottom: 6px;
                input[type="checkbox"] {
                    display: none;
                }
                input[type="checkbox"]+label {
                    cursor:pointer;
                    color:#45332c;
                    font-family: 'Roboto', sans-serif;
                    font-size: 14px;
                    display:block;
                    padding: 5px 0 0 28px;
                    background: url(../img/check.png) no-repeat 0 50%;
                    min-height: 19px;
                }
                input[type="checkbox"]:checked + label {
                    background: url(../img/check2.png) no-repeat 0 50%;
                }
            }
        }
    }
}
.region{
    margin-bottom: 18px;
    .region-btn{
        cursor: pointer;
        position: relative;
        padding-left: 25px;
        background-color: #45332c;
        -webkit-box-shadow: 0 2px 2px rgba(0, 1, 1, 0.4);
        -moz-box-shadow: 0 2px 2px rgba(0, 1, 1, 0.4);
        box-shadow: 0 2px 2px rgba(0, 1, 1, 0.4);
        height: 45px;
        line-height: 47px;
        width: 100%;
        color: #fff;
        font-size: 14px;
        text-transform: uppercase;
        &:after{
            content: '';
            display: block;
            clear: both;
            position: absolute;
            top: 50%;
            right: 15px;
            background: url(../img/icon53.png) no-repeat;
            width: 16px;
            height: 11px;
            -webkit-transform: translate(0, -50%);
            -moz-transform: translate(0, -50%);
            -ms-transform: translate(0, -50%);
            -o-transform: translate(0, -50%);
            transform: translate(0, -50%);
        }
    }
    .region-dop{
        display: block;
        background-color: #fff;
        border: 1px solid #45332c;
        border-top: none;
        padding: 20px 12px;

    }
}
/* hide original element */
.jcf-hidden {
    display: block !important;
    position: absolute !important;
    left: -9999px !important; /* change to right: -9999px on RTL sites */
}
/* custom select styles */
.select-area {
    border: 1px solid #49342D;
    position: relative;
    overflow: hidden;
    cursor: pointer;
    height: 40px;
    width: 100% !important;
}
.select-area .center {
    white-space: nowrap;
    display: block;
    padding: 0 0 0 16px;
    line-height: 38px;
    color: #45332c;
    font-size: 14px;
}
.select-area .select-opener {
    background: url(../img/select.png) no-repeat;
    position: absolute;
    height: 7px;
    width: 8px;
    right: 12px;
    top: 16px;
}
.select-options {
    position: absolute;
    overflow: hidden;
    background: #fff;
    z-index: 2000;
}
.select-options .drop-holder {
    border: 1px solid #49342D;
    overflow: hidden;
    height: 1%;
}
.select-options ul {
    list-style: none;
    padding: 0;
    margin: 0;
}
.select-options ul li {
    width: 100%;
    float: left;
}
.select-options ul a {
    text-decoration: none;
    padding: 8px 16px;
    display: block;
    cursor: pointer;
    color: #45332c;
    font-size: 14px;
}
.select-options .item-selected a {
    text-decoration: none;
    background: #45332c;
    color: #fff;
}
.customer-block10{
    .customer-container {
        .top {
            .top {
                .left{
                    span{
                        text-transform: uppercase;
                        font-size: 13px;
                        font-weight: bold;
                    }
                    em{
                        display: block;
                        text-align: center;
                        font-weight: bold;
                        font-size: 10px;
                        color: #fff;
                        text-transform: uppercase;
                        width: 70px;
                        height:16px;
                        background-color: #00B62E;
                        line-height: 16px;
                    }
                }
                .right2{
                    padding-left: 100px;
                    position: relative;
                    padding-bottom: 15px;
                    &:after{
                        content: '';
                        display: block;
                        clear: both;
                        position: absolute;
                        top: 5px;
                        left: 0;
                        width: 91px;
                        height: 88px;
                        background: url(../img/graffic3.png) no-repeat;
                        -webkit-background-size: 100% 100%;
                        background-size: 100% 100%;
                    }
                    ul{
                        li{
                            strong{
                                font-size: 14px;
                            }
                            span{
                                font-size: 13px;
                            }
                            a{
                                font-size: 13px;
                            }
                        }
                    }
                }
                .center{
                    .center-block{

                    }
                }
                .services-block{
                    ul{
                        display: flex;
                        flex-wrap: wrap;
                        li{
                            margin: 0 6px 6px 0;
                            span{
                                display: block;
                                color: #352a26;
                                font-size: 14px;
                                background-color: #fff;
                                border: 1px solid #392C29;
                                height:32px;
                                line-height: 30px;
                                padding: 0 20px;
                            }
                        }
                    }
                }
            }
        }
    }
}
.customer-block11 .customer-container{
    border: 2px solid #49342D;
    &:after{
        background: url(../img/icon80.png);
        left: -21px;
    }
}
.main-container5{
    width: 100%;
}
.customer-page{
    display: flex;
    .left{
        min-width: 254px;
        width: 254px;
        margin-right: 30px;
        margin-bottom: 30px;
        .foto-master{
            position: relative;
            margin-bottom: 11px;
            img{
                display: block;
                width: 100%;
                height:auto;
            }
            &:after{
                @include after;
                position: absolute;
                top:0;
                left: 0;
                background: url(../img/maska.png) no-repeat;
                -webkit-background-size: 100% 100%;
                background-size: 100% 100%;
                width: 100%;
                height:100%;
            }
        }
        .overall-rating{
            display: flex;
            justify-content: space-between;
            align-items: center;
            border: 1px solid #49342D;
            width: 100%;
            height:45px;
            padding: 0 17px;
            margin-bottom: 10px;
            background-color: #D3CDC8;
            span{
                display: block;
                font-weight: bold;
                font-size: 16px;
                color: #45332c;
            }
            b{
                display: block;
                font-weight: bold;
                font-size: 20px;
                color: #45332c;
            }
        }
        .status{
            background-color: #fff;
            margin-bottom: 10px;
            padding: 17px 18px 5px 18px;
            span{
                display: block;
                margin-bottom: 11px;
                font-size: 14px;
                color: #352a26;
                b{
                    font-weight: bold;
                }
            }
        }
        .buttons{
            a{
                display: block;
                margin-bottom: 6px;
                text-decoration: none;
                height:45px;
                font-size: 14px;
                line-height: 45px;
                color: #fff;
                padding-left: 40px;
                background-color: #53b412;
                -webkit-box-shadow: 0 2px 2px rgba(0,0,0, 0.4);
                -moz-box-shadow: 0 2px 2px rgba(0,0,0, 0.4);
                box-shadow: 0 2px 2px rgba(0,0,0, 0.4);
                @include transition;
                &:nth-child(1) {
                    background-image: url("../img/icon82.png");
                    background-position: 9px 50%;
                }
                &:nth-child(2) {
                    background-image: url("../img/icon83.png");
                    background-position: 9px 50%;
                }
                &:nth-child(3) {
                    background-image: url("../img/icon84.png");
                    background-position: 9px 50%;
                }
                &:nth-child(4) {
                    background-image: url("../img/icon85.png");
                    background-position: 10px 50%;
                    margin: 0;
                }
                background-repeat: no-repeat;
                &:hover{
                    background-color: #5bc017;
                }
            }
        }
    }
    .right{
        width: 100%;
        padding: 30px 20px;
        background-color: #fff;
        margin-bottom: 30px;
        span.title-master{
            display: block;
            font-weight: bold;
            font-size: 22px;
            color: #b43709;
            margin-bottom: 30px;
        }
        ul{
            margin-bottom: 22px;
            li{
                display: flex;
                margin-bottom: 9px;
                span{
                    display: block;
                    width: 130px;
                    color: #352a26;
                    min-width: 130px;
                    font-size: 14px;
                }
                b{
                    display: block;
                    color: #352a26;
                    font-weight: bold;
                    font-size: 14px;
                }
            }
        }
        .kredo{
            display: flex;
            margin-bottom: 33px;
            align-items: center;
            span{
                display: block;
                color: #352a26;
                font-weight: bold;
                min-width: 145px;
                font-size: 16px;
            }
            b{
                display: flex;
                align-items: center;
                color: #352a26;
                width: 100%;
                font-size: 15px;
                font-style: italic;
                background: url(../img/icon81.png) no-repeat 0 50%;
                min-height: 67px;
                padding-left: 35px;
                -webkit-background-size: 100% 100%;
                background-size: 100% 100%;
            }
        }
        .about-me{
            span{
                display: block;
                font-size: 14px;
                line-height: 18px;
                color: #b43709;
                font-style: italic;
                b{
                    font-size: 16px;
                    font-weight: bold;
                    display: block;
                    font-style: normal;
                }
                margin-bottom: 30px;
            }
            p{
                display: block;
                font-size: 14px;
                line-height: 24px;
                color: #352a26;
            }
        }
    }
}
.tabs-container{
    background-color: #fff;
    margin-bottom: 30px;
    padding-top: 20px;
}
/*tabs*/
.tab-list {
    overflow: hidden;
}
ul.tabset {
    position: relative;
    list-style: none;
    margin: 0;
    height:59px;
    width: 100%;
    padding: 0;
    display: flex;
    border-bottom: 1px solid #000;
    align-items: center;
}
ul.tabset li {
    margin: 0 25px;
    height:59px;
}
ul.tabset a {
    height:59px;
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    color: #352a26;
    font-size: 14px;
    line-height: 16px;
    position: relative;
}
ul.tabset li.active a:after,
ul.tabset a.active:after {
    @include after;
    position: absolute;
    bottom: -1px;
    left: 50%;
    background-color: #b43709;
    width:100%;
    margin-left: -50%;
    height: 3px;
}
ul.tabset li.active a,
ul.tabset a.active{
    text-decoration: none;
    color: #b43709;
}
ul.tabset a:hover {
    text-decoration: none;
    color: #b43709;
}
.tabs-block{
    padding: 28px 17px 25px 17px;
}
.customers-blog{
    margin-bottom: 20px;
    .top{
        display: flex;
        justify-content: space-between;
        margin-bottom: 13px;
        .foto{
            width: 56px;
            height: 56px;
            min-width: 56px;
            -webkit-border-radius: 100px;
            -moz-border-radius: 100px;
            border-radius: 100px;
            margin-right: 30px;
            img{
                display: block;
                width: 100%;
                height: auto;
                border: 1px solid #45332c;
                -webkit-border-radius: 100px;
                -moz-border-radius: 100px;
                border-radius: 100px;
            }
        }
        .right{
            position: relative;
            width: 100%;
            background-color: #f1e8e5;
            padding: 15px 40px 20px 40px;
            &:after{
                content: '';
                display: block;
                clear: both;
                position: absolute;
                top: 18px;
                left: -18px;
                background: url(../img/icon51.png) no-repeat 0 50%;
                width: 22px;
                height: 23px;
            }
            span{
                display: block;
                font-weight: bold;
                color: #b43709;
                font-size: 16px;
                margin-bottom: 7px;
            }
            a{
                display: block;
                text-decoration: underline;
                font-weight: bold;
                font-size: 16px;
                margin-bottom: 16px;
                color: #352a26;
                &:hover{
                    text-decoration: none;
                }
            }
            b{
                display: block;
                font-weight: bold;
                color: #b43709;
                font-size: 15px;
                margin-bottom: 3px;
            }
            p{
                display: block;
                color: #352a26;
                font-size: 14px;
                line-height: 16px;
                margin-bottom: 15px;
            }
            ul{
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;
                li{
                    width: 31%;
                    margin-bottom: 20px;
                    img{
                        display: block;
                        width: 100%;
                        height:auto;
                    }
                }
            }
        }
    }
    .bottom{
        padding:0 40px 0 86px;
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-end;
        align-items: center;
        a.write-comment{
            display: flex;
            text-align: center;
            align-items: center;
            justify-content: center;
            text-decoration: none;
            font-size: 14px;
            color: #fff;
            @include transition;
            width: 195px;
            height:40px;
            background-color: #b43709;
            -webkit-box-shadow: 0 2px 2px rgba(0,0,0, 0.4);
            -moz-box-shadow: 0 2px 2px rgba(0,0,0, 0.4);
            box-shadow: 0 2px 2px rgba(0,0,0, 0.4);
            margin-left: 30px;
            margin-bottom: 15px;
            &:hover{
                @include transition;
                background-color: #c34618;
            }
        }
        a.more{
            display: flex;
            text-align: center;
            align-items: center;
            justify-content: center;
            text-decoration: none;
            text-transform: uppercase;
            font-weight: bold;
            font-size: 11px;
            color: #fff;
            @include transition;
            width: 126px;
            margin-bottom: 15px;
            height:31px;
            background-color: #45332c;
            -webkit-box-shadow: 0 2px 2px rgba(0,0,0, 0.4);
            -moz-box-shadow: 0 2px 2px rgba(0,0,0, 0.4);
            box-shadow: 0 2px 2px rgba(0,0,0, 0.4);
            margin-left: 30px;
            -webkit-border-radius: 30px;
            -moz-border-radius: 30px;
            border-radius: 30px;
            &:hover{
                @include transition;
                background-color: #683e2e;
            }
        }
        .soc{
            display: flex;
            align-items: center;
            margin-left: 20px;
            margin-bottom: 15px;
            p{
                display: block;
                margin-right: 15px;
                color: #352a26;
                font-size: 14px;
            }
            ul{
                display: flex;
                li{
                    margin-right: 7px;
                    &:last-child{
                        margin: 0;
                    }
                    a{
                        display: block;
                        overflow: hidden;
                        -webkit-border-radius: 5px;
                        -moz-border-radius: 5px;
                        border-radius: 5px;
                        width: 31px;
                        height: 31px;
                        position: relative;
                        top: 0;
                        -webkit-transition: 0.4s;
                        -moz-transition: 0.4s;
                        -ms-transition: 0.4s;
                        -o-transition: 0.4s;
                        transition: 0.4s;
                        &:hover{
                            -webkit-transition: 0.4s;
                            -moz-transition: 0.4s;
                            -ms-transition: 0.4s;
                            -o-transition: 0.4s;
                            transition: 0.4s;
                            top: 5px;
                        }
                    }
                    &:nth-child(1) a{
                        background: url(../img/soc1.png) no-repeat;
                    }
                    &:nth-child(2) a{
                        background: url(../img/soc4.png) no-repeat;
                    }
                    &:nth-child(3) a{
                        background: url(../img/soc3.png) no-repeat;
                    }
                    &:nth-child(4) a{
                        background: url(../img/soc2.png) no-repeat;
                    }
                }
            }
        }
    }
}
.inner-page{
    margin-bottom: 25px !important;
}
.customer-page2{
    .left{
        .foto-master:after {
            content: '';
            display: block;
            clear: both;
            position: absolute;
            top: 0;
            left: 0;
            background: url(../img/maska2.png) no-repeat;
            -webkit-background-size: 100% 100%;
            background-size: 100% 100%;
            width: 100%;
            height: 100%;
        }
        .guarantee{
            background-color: #fff;
            margin-bottom: 10px;
            padding: 17px 18px 17px 18px;
            p{
                display: block;
                font-size: 14px;
                color: #352a26;
                line-height: 20px;
                b{
                    font-weight: bold;
                    color: #b43709;
                }
            }
        }
    }
    .right{
        position: relative;
        padding-bottom: 10px;
        .status5{
            position: absolute;
            top:27px;
            right: 20px;
            background: url(../img/icon87.png) no-repeat;
            width: 37px;
            height: 82px;
            ul{
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                padding-top: 6px;
                margin: 0;
                li{
                    margin-bottom: 3px;
                    position: relative;
                    .status5-dop{
                        position: absolute;
                        top:-6px;
                        right: 46px;
                        white-space: nowrap;
                        background-color:#f1e8e5;
                        padding: 10px;
                        font-style: italic;
                        color: #352a26;
                        font-size: 13px;
                        &:after{
                            @include after;
                            position: absolute;
                            top:50%;
                            background: url(../img/icon52.png) no-repeat 100% 50%;
                            right: -12px;
                            width: 14px;
                            height:14px;
                            margin-top: -7px;
                        }
                    }
                    b{
                        display: block;
                        -webkit-border-radius: 100%;
                        -moz-border-radius: 100%;
                        border-radius: 100%;
                        width: 21px;
                        height: 21px;
                    }
                    &:first-child b{
                        background-color: #f80202;
                    }
                    &:nth-child(2) b{
                        background-color: #f9f059;
                    }
                    &:last-child b{
                        background-color: #49f32d;
                    }
                }
            }
        }
        .kredo{
            margin-bottom: 10px;
        }
        .video-call{
            margin-bottom: 23px;
            display: flex;
            align-items: center;
            span{
                display: block;
                font-weight: bold;
                color: #b43709;
                font-size: 16px;
                margin-right: 12px;
            }
            a{
                display: block;
                background: url(../img/icon86.png) no-repeat;
                width: 46px;
                height: 35px;
                min-width: 46px;
            }
        }
        .about-me{
            margin-bottom: 30px;
        }
        .buttons-info{
            ul{
                display: flex;
                flex-wrap: wrap;
                margin: 0 -4px;
                li{
                    padding: 0 4px;
                    margin-bottom: 8px;
                    width: 20%;
                    div{
                        border: 1px solid #392C29;
                        width: 100%;
                        padding: 7px 0;
                        background-color: #F4E8E3;
                        cursor: pointer;
                        display: flex;
                        justify-content: center;
                        flex-direction: column;
                        align-items: center;
                        b{
                            display: block;
                            text-align: center;
                            font-weight: bold;
                            color: #352a26;
                            font-size: 30px;
                            margin-bottom: 3px;
                        }
                        span{
                            display: block;
                            text-align: center;
                            color: #352a26;
                            font-size: 12px;
                            line-height: 14px;
                        }
                    }
                    .views{
                        opacity:0.2;
                        cursor: inherit;
                    }
                    .views2{
                        opacity:0.2;
                        cursor: inherit;
                    }
                }
            }
        }
    }
}
.tabs-container2{
    padding-top: 0;
    .tabs-block2{
        padding: 30px 20px 0 20px;
        ul{
            display: flex;
            flex-wrap: wrap;
            margin: 0 -9px;
            li{
                padding: 0 9px;
                margin-bottom: 18px;
                width: 20%;
                img{
                    display: block;
                    width: 100%;
                    height:auto;
                }
            }
        }
    }
}
.tabs-container3{
    ul.tabset li.active a:after, ul.tabset a.active:after {
        bottom: -2px;
    }
    .tabs-block3{
        padding: 35px 25px 0 25px;
        .information{
            display: flex;
            .number{
                display: flex;
                align-items: center;
                justify-content: center;
                text-align: center;
                -webkit-border-radius: 100px;
                -moz-border-radius: 100px;
                border-radius: 100px;
                font-weight: 900;
                font-size: 26px;
                border: 2px solid #392C29;
                min-width: 65px;
                height:65px;
                width: 65px;
                margin: 0 20px 30px 0;
            }
            .img{
                margin: 0 35px 35px 0;
                width: 48.5%;
                img{
                    display: block;
                    width: 100%;
                    height:auto;
                }
                .address{
                    background: url(../img/marker3.png) no-repeat 0 50%;
                    padding-left: 31px;
                    font-size: 13px;
                    line-height: 14px;
                    color: #352a26;
                    margin-top: 9px;
                    b{
                        font-weight: bold;
                    }
                }
            }
            .right{
               margin-bottom: 30px;
                width: 100%;
                .information-block{
                    position: relative;
                    width: 100%;
                    background-color: #f1e8e5;
                    padding: 23px;
                    margin-bottom: 14px;
                    &:after{
                        content: '';
                        display: block;
                        clear: both;
                        position: absolute;
                        top: 18px;
                        left: -27px;
                        background: url(../img/icon51.png) no-repeat 0 50%;
                        width: 30px;
                        height: 38px;
                    }
                    span{
                        display: block;
                        font-weight: bold;
                        font-size: 16px;
                        margin-bottom: 20px;
                        color: #352a26;
                    }
                    b{
                        display: block;
                        color: #352a26;
                        font-size: 16px;
                    }
                    p{
                        display: block;
                        font-size: 14px;
                        line-height: 16px;
                        color: #352a26;
                    }
                }
                a.btn1{
                    margin: 0;
                    font-size: 13px;
                    width: 158px;
                    height:39px;
                    line-height: 39px;
                }
            }
        }
    }
}
.tabs-container3{
    .tabs-block4{
        .information{
            .right{
                .information-block{
                    span{
                        margin-bottom: 7px;
                    }
                    b{
                        margin-bottom: 13px;
                    }
                    ul{
                        li{
                            font-size: 16px;
                            color: #352a26;
                            margin-bottom: 5px;
                            padding-left: 29px;
                            min-height: 20px;
                            display: flex;
                            align-items: center;
                        }
                        li:first-child{
                            background: url(../img/icon88.png) no-repeat 2px 0;
                        }
                        li:nth-child(2) {
                            background: url(../img/icon89.png) no-repeat 0 0;
                        }
                        li:nth-child(3) {
                            background: url(../img/icon90.png) no-repeat 0 0;
                        }
                        li:nth-child(4) {
                            background: url(../img/icon91.png) no-repeat 0 0;
                        }
                    }
                    a{
                        display: block;
                        text-decoration: underline;
                        font-size: 16px;
                        font-weight: bold;
                        margin-top: 20px;
                        color: #352a26;
                        &:hover{
                            text-decoration: none;
                        }
                    }
                }
            }
        }
    }
}
.tabs-block5{
    padding: 25px 25px 5px 25px;
    .questionnaire{
        padding-bottom: 5px;
        margin-bottom: 30px;
        border-bottom: 1px dashed #392C29;
        b{
            display: block;
            font-size: 14px;
            line-height: 16px;
            font-weight: bold;
            color: #352a26;
            margin-bottom: 22px;
        }
        .row{
            display: flex;
            .left{
                min-width: 150px;
                width: 150px;
                margin-right: 12px;
                margin-bottom: 30px;
                .text{
                    padding: 8px 7px;
                    margin-bottom: 8px;
                }
                .select-area{
                    min-height: 50px;
                    span.left{
                        margin: 0;
                    }
                    .center{
                        white-space: inherit;
                        line-height: normal;
                        margin: 0;
                        display: flex;
                        height: 50px;
                        padding-left: 7px;
                        align-items: center;
                    }
                    .select-opener{
                        top:21px;
                    }
                }
            }
            .center{
                width: 50%;
                margin-right: 12px;
                .text{
                    padding: 8px 14px;
                }
            }
            .right{
                width: 50%;
                margin-right: 0;
                .text{
                    padding: 8px 14px;
                }
            }
        }
        .row2 .center{
            width:100%;
            margin-right: 0;
        }
        .row3{
            .left{
                min-width: 190px;
                width: 190px;
            }
            .center{
                width: 100%;
                margin-right: 0;
            }
        }
        &:last-child{
            border: none;
            margin: 0;
        }
        .text{
            font-size: 14px;
            line-height: 16px;
            color: #352a26;
            border: 1px solid #45332c;
            min-height: 50px;
            padding: 8px 14px;
            align-items: center;
            display: flex;

        }
        em{
            display: block;
            font-style: italic;
            font-weight: 500;
            color: #b43709;
            font-size: 12px;
            margin-bottom: 4px;
            margin-left: 8px;
        }
        p{
            font-size: 14px;
            margin-left: 8px;
            color: #b43709;
        }
    }
}
a.notebook{
   cursor: pointer;
}
.popup3{
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height:100%;
    z-index:99;
    padding: 15px;
    .close5{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height:100%;
        cursor: pointer;
        background-color: rgba(0,0,0, 0.5);
    }
    .popup-container{
        padding: 0;
        height: 100%;
        margin: 0 auto;
        overflow-y: auto;
        .popup-block{
            max-width: 960px;
            background-color: #fff;
            margin: 0 auto;
            position: relative;
            padding: 0;
            -webkit-border-radius: 5px;
            -moz-border-radius: 5px;
            border-radius: 5px;
            overflow: hidden;
            min-height: 100%;
            .close6{
                position: absolute;
                cursor: pointer;
                top: 13px;
                right: 13px;
                background: url(../img/close2.png) no-repeat;
                width: 23px;
                height:23px;
            }
            .title-popup{
                border-bottom: 1px solid #392C29;
                padding: 0 17px 0 24px;
                display: flex;
                margin-bottom: 35px;
                span{
                    display: block;
                    font-size: 14px;
                    color: #b43709;
                    padding: 17px 0;
                    position: relative;
                    &:after{
                        @include after;
                        position: absolute;
                        left: 0;
                        bottom: -2px;
                        width: 80px;
                        height:3px;
                        background-color: #b43709;
                    }
                }
            }
            ul.tabset{
                height:auto;
                display: flex;
                justify-content: center;
                border: none;
                margin-bottom: 40px;
                li{
                    margin: 0 12px;
                    height: auto;
                    a{
                        height: 40px;
                        text-decoration: none;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        text-align: center;
                        color: #fff;
                        font-size: 14px;
                        line-height: 14px;
                        position: static;
                        padding: 0 25px;
                        background-color: #49342D;
                        -webkit-box-shadow: 0 2px 2px rgba(0,0,0, 0.4);
                        -moz-box-shadow: 0 2px 2px rgba(0,0,0, 0.4);
                        box-shadow: 0 2px 2px rgba(0,0,0, 0.4);
                        @include transition;
                        &:hover{
                            background-color: #b43709;
                        }
                        &:after{
                            display: none !important;
                        }
                    }
                }
            }
            ul.tabset li.active a, ul.tabset a.active{
                background-color: #b43709;
            }
            .tabs-popup-block{
                width: 100%;
                height:100%;
                padding: 0 35px 35px;
                position: relative;
                &:after{
                    @include after;
                    position: absolute;
                    top:0;
                    left: 35px;
                    height:20px;
                    width: 1px;
                    background-color: #000;
                }
                textarea{
                    display: block;
                    width: 100%;
                    height: 200px;
                    border: none;
                }
            }
            .parent-focus:after{
                display: none;
            }
        }
    }
}
.tabs-popup-block2{
    padding: 0;
    ul{
        display: flex;
        justify-content: space-between;
        li{
            width: 33%;
            border-right: 1px solid #49342D;
            textarea{
                display: block;
                width: 100%;
                height: 200px;
                border: none;
                padding: 0 10px;
            }
            &:last-child{
                border: none;
            }
            span{
                display: block;
                text-align: center;
                color: #352a26;
                font-size: 14px;
                font-weight: bold;
                margin-bottom: 15px;
            }
        }
    }
}
.tabs-popup-block3{
    padding: 20px;
    img{
        display: block;
        width: 100%;
        height:auto;
    }
}
.popup4{
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height:100%;
    z-index:99;
    padding: 15px;
    .close7{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height:100%;
        cursor: pointer;
        background-color: rgba(0,0,0, 0.5);
    }
    .popup-container{
        padding: 0;
        height: 100%;
        margin: 0 auto;
        overflow-y: auto;
        .popup-block{
            max-width: 960px;
            background-color: #fff;
            margin: 0 auto;
            position: relative;
            padding: 0;
            -webkit-border-radius: 5px;
            -moz-border-radius: 5px;
            border-radius: 5px;
            overflow: hidden;
            min-height: 100%;
            .close8{
                position: absolute;
                cursor: pointer;
                top: 13px;
                right: 13px;
                background: url(../img/close2.png) no-repeat;
                width: 23px;
                height:23px;
            }
            .title-popup{
                border-bottom: 1px solid #392C29;
                padding: 0 17px 0 24px;
                display: flex;
                margin-bottom: 35px;
                span{
                    display: block;
                    font-size: 14px;
                    color: #b43709;
                    padding: 17px 0;
                    position: relative;
                    &:after{
                        @include after;
                        position: absolute;
                        left: 0;
                        bottom: -2px;
                        width: 80px;
                        height:3px;
                        background-color: #b43709;
                    }
                }
            }
            ul{
                display: flex;
                padding: 0 20px;
                margin: 0 -15px;
                flex-wrap: wrap;
                li{
                    padding: 0 15px;
                    margin-bottom: 30px;
                    width: 25%;
                    a{
                        display: block;
                        position: relative;
                        img{
                            display: block;
                            width: 100%;
                            height:auto;
                        }
                        span{
                            display: flex;
                            flex-direction: column;
                            align-items: center;
                            padding-top: 10px;
                            justify-content: center;
                            position: absolute;
                            top:5px;
                            left: 5px;
                            background: url(../img/icon92.png) no-repeat;
                            width:75px;
                            height:59px;
                            color: #352a26;
                            font-size: 12px;
                            b{
                                display: block;
                                font-weight: bold;
                                font-size: 22px;
                            }
                        }
                    }
                }
            }
        }
    }
}
.popup5{
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height:100%;
    z-index:99;
    padding: 15px;
    .close9{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height:100%;
        cursor: pointer;
        background-color: rgba(0,0,0, 0.5);
    }
    .popup-container{
        padding: 0;
        height: 100%;
        margin: 0 auto;
        overflow-y: auto;
        .popup-block{
            max-width: 960px;
            background-color: #fff;
            margin: 0 auto;
            position: relative;
            padding: 0;
            -webkit-border-radius: 5px;
            -moz-border-radius: 5px;
            border-radius: 5px;
            overflow: hidden;
            min-height: 100%;
            .close10{
                position: absolute;
                cursor: pointer;
                top: 13px;
                right: 13px;
                background: url(../img/close2.png) no-repeat;
                width: 23px;
                height:23px;
            }
            .title-popup{
                border-bottom: 1px solid #392C29;
                padding: 0 17px 0 24px;
                display: flex;
                margin-bottom: 35px;
                span{
                    display: block;
                    font-size: 14px;
                    color: #b43709;
                    padding: 17px 0;
                    position: relative;
                    &:after{
                        @include after;
                        position: absolute;
                        left: 0;
                        bottom: -2px;
                        width: 80px;
                        height:3px;
                        background-color: #b43709;
                    }
                }
            }
            ul{
                display: flex;
                padding: 0 20px;
                margin: 0 -15px;
                flex-wrap: wrap;
                li{
                    padding: 0 15px;
                    margin-bottom: 30px;
                    width: 25%;
                    a{
                        display: block;
                        position: relative;
                        img{
                            display: block;
                            width: 100%;
                            height:auto;
                        }
                        span{
                            text-indent: -999999px;
                            position: absolute;
                            top:0;
                            left: 0;
                            width: 100%;
                            height:100%;
                            background: url(../img/play.png) no-repeat 50% 50%;
                        }
                    }
                }
            }
        }
    }
}
a.btn33{
    margin: 0 auto 40px;
    width: 570px;
}
.banner55{
    -webkit-box-shadow: 0 3px 5px rgba(0, 0, 0, 0.4);
    -moz-box-shadow: 0 3px 5px rgba(0, 0, 0, 0.4);
    box-shadow: 0 3px 5px rgba(0, 0, 0, 0.4);
    max-width: 1920px;
    position: relative;
    margin: 0 auto 17px;
    a.rubric{
        display: flex;
        align-items: center;
        height:48px;
        background-color: #fff;
        -webkit-box-shadow: 0 2px 2px rgba(0,0,0, 0.4);
        -moz-box-shadow: 0 2px 2px rgba(0,0,0, 0.4);
        box-shadow: 0 2px 2px rgba(0,0,0, 0.4);
        position: absolute;
        top:0;
        left: 0;
        text-decoration: none;
        font-size: 14px;
        line-height: 16px;
        color: #352a26;
        padding: 0 7px 0 50px;
        @include transition;
        opacity: 0.7;
        -webkit-opacity: 0.7;
        -moz-opacity: 0.7;
        &:hover{
            @include transition;
            -webkit-box-shadow: 0 0px 4px rgba(0,0,0, 0.4);
            -moz-box-shadow: 0 0px 4px rgba(0,0,0, 0.4);
            box-shadow: 0 0px 4px rgba(0,0,0, 0.4);
            opacity: 1;
            -webkit-opacity: 1;
            -moz-opacity: 1;
        }
    }
    a.rubric1{
        background-image: url(../img/icon27.png);
        background-repeat: no-repeat;
        background-position: 5px 50%;
        top: 3%;
        left: 2%;
    }
    a.rubric2{
        background-image: url(../img/icon34.png);
        background-repeat: no-repeat;
        background-position: 5px 50%;
        top: 4%;
        left: 21%;
    }
    a.rubric3{
        background-image: url(../img/icon33.png);
        background-repeat: no-repeat;
        background-position: 5px 50%;
        top: 2%;
        left: 63%;
        z-index: 2;
    }
    a.rubric4{
        background-image: url(../img/icon32.png);
        background-repeat: no-repeat;
        background-position: 5px 50%;
        top: 14%;
        left: 29%;
    }
    a.rubric5{
        background-image: url(../img/icon30.png);
        background-repeat: no-repeat;
        background-position: 5px 50%;
        top: 11%;
        left: 64%;
    }
    a.rubric6{
        background-image: url(../img/icon39.png);
        background-repeat: no-repeat;
        background-position: 5px 50%;
        top: 18%;
        left: 49%;

    }
    a.rubric7{
        background-image: url(../img/icon26.png);
        background-repeat: no-repeat;
        background-position: 5px 50%;
        top: 26%;
        left: 20%;
    }
    a.rubric8{
        background-image: url(../img/icon28.png);
        background-repeat: no-repeat;
        background-position: 5px 50%;
        top: 36%;
        left: 48%;
    }
    a.rubric9{
        background-image: url(../img/icon41.png);
        background-repeat: no-repeat;
        background-position: 5px 50%;
        top: 23%;
        left: 85%;
    }
    a.rubric10{
        background-image: url(../img/icon43.png);
        background-repeat: no-repeat;
        background-position: 5px 50%;
        top: 35%;
        left: 61%;
    }
    a.rubric11{
        background-image: url(../img/icon29.png);
        background-repeat: no-repeat;
        background-position: 5px 50%;
        top: 36%;
        left: 89%;
    }
    a.rubric12{
        background-image: url(../img/icon45.png);
        background-repeat: no-repeat;
        background-position: 5px 50%;
        top: 41%;
        left: 12%;
    }
    a.rubric13{
        background-image: url(../img/icon44.png);
        background-repeat: no-repeat;
        background-position: 5px 50%;
        top: 47%;
        left: 59%;
    }
    a.rubric14{
        background-image: url(../img/icon38.png);
        background-repeat: no-repeat;
        background-position: 5px 50%;
        top: 55%;
        left: 30%;
    }
    a.rubric15{
        background-image: url(../img/icon37.png);
        background-repeat: no-repeat;
        background-position: 5px 50%;
        top: 59%;
        right: 1%;
        left: auto;
    }
    a.rubric16{
        background-image: url(../img/icon40.png);
        background-repeat: no-repeat;
        background-position: 5px 50%;
        top: 62%;
        left: 59%;
    }
    a.rubric17{
        background-image: url(../img/icon36.png);
        background-repeat: no-repeat;
        background-position: 5px 50%;
        top: 70%;
        left: 74%;
    }
    a.rubric18{
        background-image: url(../img/icon31.png);
        background-repeat: no-repeat;
        background-position: 5px 50%;
        top: 92%;
        left: 14%;
    }
    a.rubric19{
        background-image: url(../img/icon35.png);
        background-repeat: no-repeat;
        background-position: 5px 50%;
        top: 86%;
        left: 47%;
    }
    a.rubric20{
        background-image: url(../img/icon42.png);
        background-repeat: no-repeat;
        background-position: 5px 50%;
        bottom: 1%;
        left: 73%;
        top:auto;
    }
    a.customers-btn{
        display: block;
        text-align: center;
        text-decoration: none;
        position: absolute;
        top: 42%;
        left: 71%;
        font-size: 14px;
        color: #352a26;
        text-transform: uppercase;
        width: 106px;
        height: 36px;
        line-height: 38px;
        background-color: #9af6fc;
        -webkit-box-shadow: 0 2px 2px rgba(0,0,0, 0.4);
        -moz-box-shadow: 0 2px 2px rgba(0,0,0, 0.4);
        box-shadow: 0 2px 2px rgba(0,0,0, 0.4);
        opacity: 0.7;
        -webkit-opacity: 0.7;
        -moz-opacity: 0.7;
        &:hover{
            @include transition;
            opacity: 1;
            -webkit-opacity: 1;
            -moz-opacity: 1;
        }
    }
    img{
        display: block;
        width: 100%;
        height: auto;
    }
    .rubrics{
        position: absolute;
        top:0;
        left: 0;
        width: 100%;
        height: 100%;
    }
    .we-masters{
        width: 204px;
        height:124px;
        position: absolute;
        padding:45px 0 0 82px;
        top: 2%;
        left: 52%;
        background: url(../img/icon112.png) no-repeat;
        a{
            display: block;
            text-align: center;
            text-decoration: none;
            font-size: 14px;
            color: #fff;
            text-transform: uppercase;
            width: 102px;
            height: 25px;
            line-height: 27px;
            background-color: #b12d00;
            -webkit-box-shadow: 0 2px 2px rgba(0,0,0, 0.4);
            -moz-box-shadow: 0 2px 2px rgba(0,0,0, 0.4);
            box-shadow: 0 2px 2px rgba(0,0,0, 0.4);
        }
    }
}
.what-useful{
    background-color: #fff;
    padding: 40px 30px 0;
    margin-bottom: 35px;
    .title{
        margin-bottom: 45px;
    }
    .top{
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 85px;
        .left{
            padding-top: 26px;
            ul{
                display: flex;
                flex-wrap: wrap;
                width: 600px;
                justify-content: space-between;
                li{
                    position: relative;
                    span{
                        display: block;
                        background: url(../img/icon114.png) no-repeat 50% 50%;
                        height:136px;
                        width: 136px;
                        margin: 0 auto 10px;
                        position: relative;
                        z-index: 2;
                    }
                    b{
                        display: block;
                        text-align: center;
                        font-size: 16px;
                        text-transform: uppercase;
                        font-weight: 900;
                        color: #352a26;
                    }
                    p{
                        display: block;
                        text-align: center;
                        color: #352a26;
                        font-size: 16px;
                        line-height: 18px;
                    }
                    &:first-child:after{
                        @include after;
                        position: absolute;
                        top: 59px;
                        left: 121px;
                        background: url(../img/icon117.png) no-repeat;
                        width: 125px;
                        height:17px;
                    }
                    &:nth-child(2) {
                        &:after{
                            @include after;
                            position: absolute;
                            top: 59px;
                            left: 153px;
                            background: url(../img/icon117.png) no-repeat;
                            width: 125px;
                            height:17px;
                        }
                        span{
                            background: url(../img/icon115.png) no-repeat 50% 50%;
                        }
                    }
                    &:nth-child(3) span{
                        background: url(../img/icon116.png) no-repeat 50% 50%;
                    }
                    &:nth-child(3):after{
                        @include after;
                        position: absolute;
                        top: -31px;
                        left: 116px;
                        background: url(../img/icon118.png) no-repeat;
                        width: 114px;
                        height:198px;
                    }
                    &:nth-child(3):before{
                        @include after;
                        position: absolute;
                        top: 67px;
                        left: -406px;
                        background: url(../img/icon119.png) no-repeat;
                        width: 859px;
                        height:277px;
                    }
                }
            }
        }
        .right{
            margin-right: 40px;
            position: relative;
            z-index: 3;
            ul{
                display: flex;
                flex-wrap: wrap;
                flex-direction: column;
                li{
                    background: url(../img/icon113.png) no-repeat 0 0;
                    padding-left: 68px;
                    padding-top: 20px;
                    margin-bottom: 40px;
                    p{
                        display: block;
                        font-size: 16px;
                        color: #352a26;
                        line-height: 18px;
                    }
                    a{
                        display: block;
                        text-decoration: underline;
                        font-size: 16px;
                        color: #352a26;
                        line-height: 18px;
                        text-transform: uppercase;
                        text-shadow: 0 0 0;
                        &:hover{
                            text-decoration: none;
                        }
                    }
                }
            }
        }
    }
    .bottom{
        ul{
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            li{
                background: url(../img/icon113.png) no-repeat 0 0;
                padding-left: 68px;
                padding-top: 20px;
                margin-bottom: 40px;
                p{
                    display: block;
                    font-size: 16px;
                    line-height: 18px;
                    color: #352a26;
                }
                a{
                    display: block;
                    text-decoration: underline;
                    font-size: 16px;
                    color: #352a26;
                    line-height: 18px;
                    text-transform: uppercase;
                    text-shadow: 0 0 0;
                    &:hover{
                        text-decoration: none;
                    }
                }
            }
        }
    }
    .what-useful-block2{
        .top{
            align-items: inherit;
            margin-bottom: 150px;
        }
        .top .left{
            padding-top: 59px;
        }
        .top .left ul li:nth-child(2):after {
            left: 129px;
        }
        .top .left ul li:nth-child(3):after {
            top: -31px;
            left: 116px;
            height: 112px;
        }
        .top .left ul li:nth-child(3):before {
            @include after;
            position: absolute;
            top: 67px;
            left: -410px;
            background: url(../img/icon120.png) no-repeat;
            width: 839px;
            height:277px;
        }
        .top .right{
            margin-right: 80px;
        }
    }
}
.how-it-works-container{
    background-color: #fff;
    padding: 50px 15px 40px;
    .title55{
        display: block;
        text-align: center;
        text-transform: uppercase;
        font-weight: 900;
        color: #352a26;
        font-size: 16px;
        margin-bottom: 40px;
    }

    .title{
        margin-bottom: 30px;
    }
    a.btn5{
        display: block;
        text-align: center;
        margin: 0 auto;
        text-decoration: none;
        font-size: 26px;
        color: #fff;
        font-weight: bold;
        width: 531px;
        height:54px;
        line-height: 56px;
        background-color: #b43709;
        @include transition;
        margin-bottom: 50px;
        &:hover{
            @include transition;
            background-color: #c34618;
        }
    }
    .graphics{
        padding: 0 40px;
        ul{
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            li{
                margin-bottom: 40px;
                width: 28%;
                img{
                    display: block;
                    width: 100%;
                    height:auto;
                    margin-bottom: 25px;
                }
                p{
                    display: block;
                    text-align: center;
                    font-size: 16px;
                    line-height: 18px;
                    color: #352a26;
                }
                b{
                    display: block;
                    margin-bottom: 15px;
                    color: #352a26;
                    text-align: center;
                    font-weight: bold;
                    font-size: 18px;
                    text-transform: uppercase;
                }
            }
        }
    }
    .graphics2{
        ul{
            justify-content: center;
            li{
                padding: 0 85px;
                width: 46%;
                position: relative;
                &:first-child:after{
                    @include after;
                    left: 88%;
                    position: absolute;
                    top: 126px;
                    background: url(../img/icon121.png) no-repeat;
                    width: 115px;
                    height: 52px;
                }
            }
        }
    }
    .graphics3{
        ul{
            justify-content: space-between;
            li{
                width: 18%;
            }
        }
    }
    p.info{
        display: block;
        text-align: center;
        font-size: 16px;
        line-height: 22px;
        color: #352a26;
        padding: 0 30px;
        margin-bottom: 25px;
    }
    p.info2{
        display: block;
        text-align: center;
        font-size: 16px;
        line-height: 22px;
        color: #352a26;
        padding: 0 80px;
        margin-bottom: 25px;
    }
    strong.info{
        display: block;
        text-align: center;
        font-size: 16px;
        line-height: 22px;
        font-weight: bold;
        color: #352a26;
        padding: 0 30px;
        margin-bottom: 25px;
    }
    a.more{
        display: block;
        text-align: center;
        text-decoration: none;
        font-size: 13px;
        text-transform: uppercase;
        font-weight: bold;
        color: #fff;
        width: 158px;
        height: 39px;
        line-height: 41px;
        -webkit-border-radius: 30px;
        -moz-border-radius: 30px;
        border-radius: 30px;
        background-color: #45332c;
        -webkit-box-shadow: 0 2px 2px rgba(0,0,0, 0.4);
        -moz-box-shadow: 0 2px 2px rgba(0,0,0, 0.4);
        box-shadow: 0 2px 2px rgba(0,0,0, 0.4);
        @include transition;
        margin: 0 auto 50px;
        &:hover{
            @include transition;
            background-color: #b43709;
        }
    }
}
.list-helper{
    padding: 0 70px;
    p{
        display: block;
        color: #352a26;
        font-size: 16px;
        line-height: 22px;
        margin-bottom: 20px;
        strong{
            font-weight: bold;
            color: #b43709;
            font-size: 20px;
        }
    }
}
.place-order{
    display: flex;
    flex-wrap: wrap;
    padding: 0 25px;
    justify-content: space-between;
    .left{
        padding-top: 60px;
        ul{
            display: flex;
            flex-wrap: wrap;
            width: 400px;
            justify-content: space-between;
            li{
                position: relative;
                span{
                    display: block;
                    background: url(../img/icon114.png) no-repeat 50% 50%;
                    height: 136px;
                    width: 136px;
                    margin: 0 auto 10px;
                    position: relative;
                    z-index: 2;
                }
                &:nth-child(2) span{
                    background: url(../img/icon115.png) no-repeat 50% 50%;
                }
                b{
                    display: block;
                    text-align: center;
                    font-size: 16px;
                    text-transform: uppercase;
                    font-weight: 900;
                    color: #352a26;
                    margin-bottom: 17px;
                }
                p{
                    display: block;
                    text-align: center;
                    color: #352a26;
                    font-size: 16px;
                    line-height: 18px;
                }
                &:first-child:after{
                    content: '';
                    display: block;
                    clear: both;
                    position: absolute;
                    top: 59px;
                    left: 146px;
                    background: url(../img/icon117.png) no-repeat;
                    width: 125px;
                    height: 17px;
                }
                &:nth-child(2):after{
                    content: '';
                    display: block;
                    clear: both;
                    position: absolute;
                    top: -32px;
                    left: 107px;
                    background: url(../img/icon137.png) no-repeat;
                    width: 114px;
                    height: 198px;
                }
            }
        }
    }
    .right{
        .top{
            margin-bottom: 78px;
            ul{
                display: flex;
                flex-wrap: wrap;
                width: 550px;
                li{
                    background: url(../img/icon113.png) no-repeat 0 0;
                    padding-left: 68px;
                    padding-top: 20px;
                    margin-bottom: 40px;
                    position: relative;
                    p{
                        display: block;
                        font-size: 16px;
                        line-height: 18px;
                        color: #352a26;
                    }
                    &:first-child:after{
                        content: '';
                        display: block;
                        clear: both;
                        position: absolute;
                        top: 19px;
                        left: 175px;
                        background: url(../img/icon117.png) no-repeat;
                        width: 125px;
                        height: 17px;
                    }
                    &:first-child{
                        width: 310px;
                    }
                }
            }
        }
        .bottom{
            margin-bottom: 0;
            ul{
                li{
                    background: url(../img/icon126.png) no-repeat 0 0;
                }
            }
        }
    }
}
.info-smile{
    margin-bottom: 25px;
    padding: 0 15px;
    ul{
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        li{
            margin-bottom: 25px;
        }
        img{
            display: block;
            margin: 0 auto 30px;
        }
        p{
            display: block;
            text-align: center;
            color: #352a26;
            font-size: 16px;
            line-height: 18px;
        }
    }
}
.sidebar2{
    display: none;
}
#scroller{
    display: block;
    text-decoration: none;
    position: fixed;
    bottom: 20px;
    right: 15px;
   cursor: pointer;
    background: url(../img/up.png) no-repeat 10px 50% rgba(0,0,0, 0.3);
    color: #fff;
    padding: 15px 15px 15px 35px;
    @include transition;
    z-index: 15;
    &:hover{
        @include transition;
        background: url(../img/up.png) no-repeat 10px 50% rgba(0,0,0, 0.7);
    }
}




































/*media*/
@media screen and (max-width: 1850px) {
    .banner55 .we-masters {
        top: 1.7%;
        left: 52%;
    }
}
@media screen and (max-width: 1800px) {
    .banner55 .we-masters {
        top: 1.4%;
        left: 52%;
    }
}
@media screen and (max-width: 1750px) {
    .banner55 .we-masters {
        top: 1.1%;
        left: 52%;
    }
}
@media screen and (max-width: 1700px) {
    .banner55 .we-masters {
        top: 0.7%;
        left: 51.5%;
    }
}
@media screen and (max-width: 1600px) {
    .banner55 .we-masters{
        top:0;
    }
}
@media screen and (max-width: 1500px) {
    .banner55 .we-masters{
        left: 51%;
        height: 118px;
    }
}
@media screen and (max-width: 1450px) {
    .banner55 .we-masters{
        left: 51%;
        height: 112px;
    }
}
@media screen and (max-width: 1366px) {
    .banner55 .we-masters{
        height: 107px;
        left: 50%;
    }
    .banner55 a.rubric3 {
        top: 2%;
        left: 70%;
    }


}
@media screen and (max-width: 1300px) {
    .banner55 .we-masters {
        height: 102px;
        left: 49.9%;
    }
}
@media screen and (max-width: 1250px) {
    .banner55 .we-masters {
        height: 98px;
        left: 49.9%;
    }
}
@media screen and (max-width: 1200px) {
    .banner55 .we-masters {
        height: 84px;
    }
}
@media screen and (max-width: 1150px) {
    .place-order {
        display: flex;
        flex-wrap: wrap;
        padding: 0;
        justify-content: space-between;
        flex-direction: column;
        align-items: center;
        .left{
            margin-bottom: 20px;
        }
    }
    .place-order .right .top {
        margin-bottom: 0;
    }
    .place-order .left ul li:nth-child(2):after{
        display: none;
    }
    .what-useful {
        padding: 40px 15px 0;
    }
    .what-useful .top {
        flex-direction: column !important;
        margin-bottom: 30px !important;
        align-items: center  !important;
    }
    .what-useful .top .left ul li:nth-child(3):before{
        display: none !important;
    }
    .what-useful .top .left ul li:nth-child(3):after{
        display: none !important;
    }
    .what-useful .top .left{
        margin-bottom: 25px !important;
    }
    .what-useful .top .right{
        margin-right: 0 !important;
        margin-bottom: 25px !important;
        ul{
            flex-direction: row !important;
            li{
                margin: 0 10px !important;
            }
        }
    }
    .what-useful .title {
        margin-bottom: 25px;
    }
    .banner55 a.rubric4 {
        top: 17%;
        left: 29%;
    }
    .banner55 a.rubric3 {
        top: 2%;
        left: 76%;
    }
    .banner55 a.rubric11 {
        top: 35%;
        left: 88%;
    }
    .inner-page-block2 .steps ul:after{
        display: none;
    }
}
@media screen and (max-width: 1070px) {
    .header .bottom2 .search-master-block{
        margin:15px 0;
    }
    .customer-page2 .right .buttons-info ul li{
        width: 25%;
    }
    .bottom2 .search-master-block form {
        display: flex;
        flex-wrap: wrap;
        a.rubric{
            width: 100%;
        }
        input[type="text"] {
            border:none;
            border-right: 1px solid #392C29;
            border-left: 1px solid #392C29;
        }
        input[type="submit"] {
            width: 100%;
        }
    }
}
@media screen and (max-width: 1024px) {
    .info-smile ul {
        display: flex;
        flex-wrap: wrap;
    }
}
@media screen and (max-width: 960px) {
    #scroller{
        padding: 15px 15px 15px 15px;
        background-position: 50% 50%;
        span{
            display: none;
        }
        &:hover {
            background-position: 50% 50%;
        }
    }
    .sidebar2{
        display: block;
    }
    .sidebar3{
        .my-messages:nth-child(1) {
            display: none;
        }
        .my-messages:nth-child(2) {
            display: none;
        }
        .my-messages:nth-child(3) {
            display: none;
        }
    }
    .how-it-works-container .graphics2 ul {
        justify-content: center;
        flex-direction: column;
        li:after{
            display: none !important;
        }
    }
    .how-it-works-container .graphics2 ul li {
        padding: 0;
        margin: 0 auto 25px;
        width: 280px;
        position: relative;
    }
    .info-smile ul {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        li{
            width: 25%;
        }
    }
    .how-it-works-container .graphics {
        padding: 0px;
    }
    .banner55 .rubrics {
       display: none;
    }
    .customer-page2 .right .buttons-info ul li{
        width: 20%;
    }
    .customers-blog .bottom{
        padding-right: 0;
    }
    .customers-blog .top .right {
        padding: 15px 15px 20px 15px;
    }
    .experience{
        .experience-dop{
            display: none;
        }
    }
    .region{
        .region-dop{
            display: none;
        }
    }
    .additionally{
        .additionally-dop{
            display: none;
        }
    }
    .price-block .price-dop{
        display: none;
    }
    .order-type-block .order-type-dop{
        display: none;
    }
    .customer-orders .bottom {
        padding-left:0;
    }
    .customer-orders .top .right .top-block {
        padding: 10px 15px 11px 15px;
    }
    .customer-orders .top .right b {
        padding-left: 40px;
        background: url(../img/marker.png) no-repeat 15px 50%;
    }
    .reviews-container .bottom .left .review {
        padding: 20px 35px 15px 15px;
    }
    .reviews-container .bottom .right .review {
        padding: 20px 35px 15px 15px;
    }
    .my-messages-block .sms {
        padding: 13px 15px 13px 15px;
    }
    .dop-block5 ul li {
        width: 25%;
        padding: 0 5%;
    }
    .selected-customer ul{
        flex-wrap: nowrap;
    }
    .main{
        flex-wrap: wrap;
    }
    .sidebar{
        min-width: 100%;
        width: 100%;
        margin-right: 0;
        order: 2;
    }
    .main-container{
        margin-bottom: 20px;
    }
    .maybe-interested .title-interested br{
        display: none;
    }
    .top-main{
        padding-left: 15px;
        padding-right: 15px;
        &:after{
            left: 15px;
        }
    }
    .maybe-interested .master .top {
        justify-content: center;
    }
    .maybe-interested .master .bottom {
        display: flex;
        justify-content: center;
    }
    .maybe-interested .master .bottom ul li strong{
        text-align: right;
    }
    .maybe-interested .master .bottom ul li{
        justify-content: flex-start;
    }
    .all-services-container {
        padding-left: 15px;
    }
    .rubric-dop{
        display: none;
    }
    .question .title{
        line-height: 36px;
    }
    .graph-container .graph-left {
        width: 100%;
        margin-bottom: 40px;
    }
    .graph-container .graph-right {
        width: 100%;
        background-color: #fff;
        padding: 35px 15px 0px 15px;
        position: relative;
        overflow: hidden;
    }
    .partners2 ul {
        margin: 0 -20px;
    }
    .title-inner{
        font-size: 30px;
    }
    .inner-page-block {
        padding: 40px 20px 40px;
    }
    .how-works .how-works-block{
        display: flex;
        margin-bottom: 30px;
        flex-wrap: wrap;
        flex-direction: column;
        img{
            min-width: 1px;
            width: 100%;
            height:auto;
        }
    }
    .how-works .how-works-block .right{
        padding-top: 1px;
        .title{
            height:auto;
            padding: 15px;
            br{
                display: none;
            }
        }
        .right-text{
            min-height: 1px;
            padding: 15px;
        }
    }
    .how-works .how-works-block2{
        img{
            order: 1;
        }
        .right{
            order: 2;
        }
    }
    .title{
        font-size: 30px;
    }
    .what-help-you .title{
        font-size: 30px;
    }
    .write-us .container .write-us-block{
        margin: 350px 0 0 0;
    }
    .dop-block5 ul li .instruments-dop {
        position: absolute;
        top: 112%;
        left: 18%;
    }
    .selected-customer ul li .instruments-dop {
        position: absolute;
        top: 120%;
        left: 9%;
    }
}
@media screen and (max-width: 854px) {
    .footer .container .footer-container {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        flex-direction: column;
        align-items: center;
        .center{
            margin-bottom: 20px;
        }
        .right{
            margin-bottom: 20px;
        }
    }
    .partners2 ul li {
        width: 33.333333333%;
        margin-bottom: 55px;
    }
    .all-services .container ul li{
        width: 25%;
    }
    .find-master .find-master-block ul li{
        width: 25%;
    }
}
@media screen and (max-width: 768px) {
    .how-it-works-container p.info{
        padding: 0;
    }
    .how-it-works-container strong.info{
        padding: 0;
    }
    .list-helper {
        padding: 0;
    }
    .how-it-works-container .graphics ul {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        flex-direction: column;
    }
    .how-it-works-container .graphics ul li {
        width: 260px;
        margin: 0 auto 40px;
    }
    .how-works a.btn3 {
        margin: 47px auto 0;
        width: 100%;
        font-size: 23px;
    }
    .tabs-container3 ul.tabset li {
        margin: 0 24px;
        height: 59px;
    }
    .customer-page2 .right .buttons-info ul li{
        width: 33.333333%;
    }
    .customer-block10 .customer-container .top .top .left{
        margin-bottom: 10px;
    }
    .customer-block5 .customer-container .top .top{
        justify-content: flex-start;
        .left{
            width: 100%;
        }
        .center{
            width: 100%;
        }
        .right{
            width: 100%;
        }
    }
    .question ul li{
        width: 100%;
    }
    .contacts2 .contacts-left{
        min-width: 1px;
        margin: 0;
    }
    .inner-page-block3 .good-know-block .left {
        width: 100%;
        order: 1;
        margin-bottom: 30px;
    }
    .inner-page-block3 .good-know-block .right {
        width: 100%;
        order: 2;
        margin-bottom: 0px;
    }
    .good-know-block .left {
        width: 100%;
        order: 2;
        margin-bottom: 0px;
    }
    .good-know-block .right {
        width: 100%;
        margin-bottom: 30px;
    }
    .inner-page-block2 .steps ul{
       justify-content: center;
        li{
            width: 33.33333333%;
        }
    }
    .contacts {
        display: flex;
        margin-bottom: 30px;
        flex-wrap: wrap;
    }
    .contacts .contacts-left {
        width: 100%;
        margin-right: 0;
        margin-bottom: 30px;
        order: 2;
    }
    .contacts .contacts-right {
        padding-top: 0;
        width: 100%;
        margin-bottom: 30px;
    }
    .header .bottom2 .container .logo{
        margin: 0;
    }
    .header .bottom2 .container .tel{
        margin: 0;
    }
    .header2 .bottom2 .container {
        min-height: 103px;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        padding: 15px;
    }
    .footer .container .footer-container {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        .footer-logo{
            margin-bottom: 30px;
        }
        .right{
            margin-bottom: 30px;
        }
    }
    .footer .container .nav ul li{
        margin-bottom: 15px;
    }
    .footer .container .footer-container .right .soc span{
        margin: 15px;
    }
    .all-services .container ul li{
        width: 33.333333%;
    }
    .find-master .find-master-block ul li{
        width: 33.333333%;
    }
    .search-master-block form input[type="submit"] {
        background: url(../img/icon5.png) no-repeat 15px 50% #b43709;
        padding-left: 53px;
        min-width: 150px;
        &:hover{
            background-position: 15px 50%;
        }
    }
    .search-master-block form input[type="text"]{
        padding-left: 15px;
    }
    .banner .container .search-master {
        padding: 20px 15px 20px 15px;
    }
    .search-master-block form a.rubric {
        min-width: 140px;
        background: url(../img/icon4.png) no-repeat 10px 50% #49342D;
        padding-left: 47px;
    }
}
@media screen and (max-width: 700px) {
    .reviews-container2 .top{
        flex-wrap: wrap;
        .left{
            width: 100%;
        }
    }
}
@media screen and (max-width: 640px) {
    .info-smile ul {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        width: 100%;
        li{
            width: 50%;
        }
    }
    .place-order .right{
        width: 100%;
    }
    .place-order .right .top ul {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        flex-direction: column;
        li{
            width: 100% !important;
        }
        li:after{
            display: none !important;
        }
    }
    .what-useful .top .left ul {
        flex-direction: column;
        width: 100%;
        li{
            margin-bottom: 25px;
        }
        li:after{
            display: none !important;
        }
    }
    .what-useful .top .right{
        width: 100%;
        margin-bottom: 0 !important;
    }
    .what-useful .top .right ul {
        width: 100%;
        flex-direction: column !important;
        li{
            width: 100%;
            margin:0 0 15px 0 !important;
        }
    }
    .what-useful .bottom{
        width: 100%;
    }
    .what-useful .bottom ul {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        width: 100%;
        flex-direction: column !important;
        li{
            width: 100%;
            min-height: 70px;
            margin:0 0 15px 0 !important;
        }
    }
    a.btn33{
        margin: 0 auto 40px;
        width: 100%;
        font-size: 22px;
    }
    .how-works a.btn3 {
        font-size: 21px;
    }
    .popup4 .popup-container .popup-block ul li{
        width: 50%;
    }
    .popup5 .popup-container .popup-block ul li{
        width: 50%;
    }
    .tabs-container3 ul.tabset{
        justify-content: space-around;
        li {
            margin: 0;
        }
    }
    .customer-page{
        flex-wrap: wrap;
        .left{
            min-width: 1px;
            width: 100%;
            margin: 0 0 20px 0;
        }
    }
    .reviews-container .bottom .left {
        width: 100%;
    }
    .reviews-container .bottom .right {
        width: 100%;
    }
    .reviews-container .top .left {
        width: 100%;
    }
    .partners2 ul li {
        width: 50%;
        margin-bottom: 55px;
    }
    .all-services .container ul li{
        width: 50%;
    }
    .find-master .find-master-block ul li{
        width:50%;
    }
    .write-us .container .write-us-block {
        margin-right: 0;
        -webkit-border-radius:  0;
        -moz-border-radius:  0;
        border-radius: 0;
        width: 100%;
        height: auto;
        padding:40px 15px;
    }
}
@media screen and (max-width: 568px) {
    .how-it-works-container a.btn5{
        width: 100%;
    }
    .how-works a.btn3 {
        font-size: 19px;
        line-height: 23px;
        padding: 0 10px;
    }
    .tabs-block5 {
        padding: 25px 15px 5px 15px;
    }
    .tabs-block5 .questionnaire .row {
        .left{
            width: 100% !important;
            margin-right: 0 !important;
        }
    }
    .tabs-block5 .questionnaire .row {
        display: flex;
        flex-wrap: wrap;
    }
    .tabs-container3 .tabs-block3 {
        padding: 35px 15px 0 15px;
    }
    .tabs-container3 .tabs-block3 .information .right .information-block {
        padding: 10px;
        margin-bottom: 14px;
    }
    .tabs-container3 .tabs-block3 .information .img {
        display: none;
    }
    .tabs-container3 .tabs-block3 .information .number{
        margin-right: 40px;
    }
    .tabs-container2 .tabs-block2 ul li{
        width: 25%;
    }
    .top-main .buttons{
        flex-direction: column;
        margin-bottom: 5px;
        a{
            margin: 5px 0 0;
        }
    }
    .reviews-container2 .top{
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .reviews-container2 .top .right2{
        width: 100%;
        justify-content: flex-start;
    }
    .dop-block5 ul li {
        padding: 0 4px;
    }
    .selected-customer ul{
        flex-wrap: wrap;
    }
    .inner-page-block2 .steps ul{
        justify-content: center;
        li{
            width: 50%;
        }
    }
    .bottom2 .search-master-block form input[type="text"]{
        width: 100%;
    }
    .popup1 .popup-container .popup-block .close2 {
        top: -57px;
        right: 0;
    }
    .popup2 .popup-container .popup-block .close4 {
        top: -57px;
        right: 0;
    }
    .header{
        display: flex;
        flex-direction: column;
        .bottom{
            position: relative;
            z-index: 3;
            order: 1;
        }
        .top{
            order: 2;
        }
        .cities .cities-btn{
            width: auto;
            padding-right: 40px;
        }
    }
    .header .top .container .buttons .btn-login{
        width: 70px;
    }
    .header .top .container .buttons .btn-registration{
        width: 120px;
    }
    .banner .container {
        padding-top: 200px;
    }
    .search-master-block form{
        flex-wrap: wrap;
    }
    .search-master-block form a.rubric{
        width: 100%;
        min-width: 1px;
    }
    .search-master-block form input[type="text"] {
        width: 67%;
        border-top: none;
        border-left: 1px solid #392C29;
        -webkit-border-bottom-left-radius: 5px;
        -moz-border-radius-bottomleft: 5px;
        border-bottom-left-radius: 5px;
    }
    .search-master-block form input[type="submit"]{
        min-width: 1px;
        width: 33%;
    }
}
@media screen and (max-width: 500px) {
    .header .bottom .container .tel{
        display: none;
    }
}
@media screen and (max-width: 480px) {
    .customer-block5 .customer-container .top .top .center .center-block:after{
        display: none;
    }
    .customers-blog .top{
        flex-wrap: wrap;
    }
    .customers-blog .top .foto{
        margin: 0 auto 20px;
    }
    .customers-blog .top .right:after{
        display: none;
    }
    .customer-orders .top {
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 20px;
    }
    .customer-orders .center ul li {
        margin-right: 0;
        width: 50.1%;
        margin: 0 0 20px 0;
        justify-content: center;
    }
    .customer-orders .center ul li .foto{
        margin-right: 0;
    }
    .customer-orders .top .foto{
        margin: 0 auto 20px;
    }
    .customer-orders .top .right:after{
        display: none;
    }
    .tabs-block5 .questionnaire .row .left{
        margin-bottom: 15px;
    }
    .tabs-container3 .tabs-block3 .information {
        display: flex;
        flex-wrap: wrap;
    }
    .tabs-container3 .tabs-block3 .information .number {
        margin: 0 auto 30px;
    }
    .tabs-container3 .tabs-block3 .information .right .information-block:after{
        display: none;
    }
    .tabs-container3 .tabs-block3 .information .right a.btn1{
        margin: 0 auto;
    }
    .place-order .left ul {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        justify-content: space-between;
        flex-direction: column;
        li{
            margin: 0 0 20px 0;
        }
        li:after{
            display: none !important;
        }
    }
    a.btn33{
        margin: 0 auto 40px;
        width: 100%;
        font-size: 20px;
        line-height: 24px;
        padding: 0 10px;
    }
    .what-help-you .container .text a.btn3 {
        margin: 0 auto 25px;
        font-size: 20px;
        width: 94%;
    }
    .tabs-container3 ul.tabset{
        height: auto;
        justify-content: space-around;
        li {
            margin: 0;
            a{
                font-size: 12px;
            }
        }
    }
    .tabs-container2 ul.tabset {
        justify-content: space-around;
        li{
            margin: 0;
        }
    }
    .tabs-container2 .tabs-block2 ul li{
        width: 33.33333%;
    }
    .customers-blog .top .right ul{
        justify-content: flex-start;
        li{
            width: 100%;
        }
    }
    .customers-blog .bottom{
        padding: 0;
        flex-direction: column;
        align-items: flex-end;
        a.more{
            margin-left: 0;
        }
        a.write-comment{
            margin-left: 0;
        }
        .soc{
            margin-left: 0;
        }
    }
    .customer-block5{
        flex-wrap: wrap;
        .foto{
            margin: 0 auto 15px;
        }
    }
    .customer-block5 .customer-container{
        width: 100%;
        &:after{
            display: none;
        }
    }
    .customer-block5 .customer-container .bottom-block{
        padding: 8px 10px 8px 10px;
        ul{
            margin-bottom: 5px;
            flex-wrap: wrap;
            li{
                margin: 0 0 5px 0;
                padding: 0;
                background: none;
                width: 100%;
            }
        }
    }
    .customer-block5 .customer-container .top {
        padding: 10px 10px 1px 10px;
    }
    .customer-block5 .customer-container .top .bottom .left span {
        margin-left: 17px;
    }
    .recommendation-master5{
        padding-left: 0;
    }
    .title-recommendation-master5{
        padding-left: 0;
    }
    .recommendation-master5 .right{
        padding-left: 0;
        width: 100%;
    }
    .recommendation-master5 .right:after{
        display: none;
    }
    .partners2 ul li {
        width: 100%;
        margin-bottom: 55px;
    }
    .contacts .contacts-left {
        width: 100%;
        background-color: #45332c;
        padding: 20px 15px;
        margin-right: 0;
        margin-bottom: 30px;
    }
    .popup1 .popup-container{
        width: 100%;
        .popup-block{
            width: 100%;
        }
    }
    .popup2 .popup-container{
        width: 100%;
        .popup-block{
            width: 100%;
        }
    }
    .footer .container .nav ul{
        flex-direction: column;
        align-items: center;
        justify-content: center;
        li{
            background: none;
            padding: 0;
            margin: 0 0 12px 0;
        }
    }
    .footer .container .footer-container .right .tel{
        flex-direction: column;
        align-items: center;
        background: none;
        padding: 0;
        strong{
            margin-bottom: 10px;
            margin-right: 0;
        }
    }
    .all-services .container ul li{
        width: 100%;
    }
    .find-master .find-master-block ul li{
        width:100%;
    }
    .search-master-block form input[type="submit"]{
        padding-left: 45px;
    }
    .banner .container .search-master .buttons {
        justify-content: center;
        flex-direction: column;
        align-items: center;
        a{
            margin-bottom: 15px;
        }
    }
    .search-master-block form input[type="text"] {
        width:100%;
        border-top: none;
        border-left: 1px solid #392C29;
        border-right: 1px solid #392C29;
        -webkit-border-bottom-left-radius: 0px;
        -moz-border-radius-bottomleft: 0px;
        border-bottom-left-radius: 0px;
        border-bottom: none;
        border-top: none;
    }
    .search-master-block form input[type="submit"]{
        min-width: 1px;
        width: 100%;
    }
    .banner .container .search-master span{
        padding-left: 0;
        line-height: 24px;
        text-align: center;
    }
    .banner .container {
        padding-top: 120px;
    }
    .what-help-you .container .text img {
        margin-bottom: 20px;
    }
    .what-help-you .container .text p{
        padding: 0 15px;
    }
    .what-help-you .container .text{
        padding-bottom: 0;
    }
    a.btn-question{
        margin-left: 0;
    }
    .customers-blog .bottom {
        padding: 0;
        flex-direction: column;
        align-items: center;
    }
    .my-messages-block{
        flex-wrap: wrap;
    }
    .my-messages-block .foto-user{
        margin: 0 auto 20px;
    }
    .my-messages-block .sms:after{
        display: none;
    }
    .my-messages-block2 .foto-user{
        order: 1;
    }
    .my-messages-block .sms{
        order: 2;
    }
    .my-messages-container2 .my-messages-block .photos2 {
        min-width: 55px;
        width: 55px;
        margin: 0 auto 20px;
    }
    a.btn-question {
        margin: 0 auto 50px;
    }
}
@media screen and (max-width: 460px) {
    .title {
        font-size: 25px;
    }
    .what-help-you .title {
        font-size: 25px;
    }
    .how-works a{
        width: 100%;
        font-size: 20px;
    }
    .write-us{
        background: none;
        min-height: 1px;
        .container{

        }
    }
    .write-us .container .write-us-block{
        margin-top: 0;
        margin-bottom: 40px;
        input[type="text"]{
            width: 100%;
        }
        textarea{
            width: 100%;
        }
        .row{
            width: 100%;
            input[type="text"]{
                width: 100%;
            }
        }
        .line{
            width: 80%;
        }
        span{
            font-size: 20px;
        }
        strong{
            font-size: 30px;
        }
    }
}
@media screen and (max-width: 400px) {
    .tabs-container3 ul.tabset li a {
        font-size: 10px;
    }
    .info-smile ul li {
        margin-right: 0;
        margin: 0 auto 25px;
        width: 100%;
    }
    .popup3 .popup-container .popup-block .tabs-popup-block {
        padding: 0 15px 35px;
    }
    .popup3 .popup-container .popup-block .tabs-popup-block:after {
        left: 15px;
    }
    .popup3 .popup-container .popup-block ul.tabset{
        justify-content: space-around;
        li {
            margin: 0;
            height: auto;
            a{
                padding: 0 15px;
            }
        }

    }
    .customer-page2 .right .status5{
        display: none;
    }
    .customer-orders .center ul{
        justify-content: center;
    }
    .customer-orders .center ul li{
        margin-right: 0;
        width: 50.1%;
    }
    .reviews-container .top .right .schedule-block{
        display: none;
    }
    .inner-page-block2 .steps ul{
        justify-content: center;
        li{
            width: 100%;
        }
    }
    .header .top .container {
        height:auto;
        .buttons{
            flex-direction: column;
            padding: 15px 0;
            align-items: center;
            .btn-login{
                margin: 0 0 10px 0;
            }
        }
    }
}
@media screen and (max-width: 360px) {
    a.btn-question{
        width: 100%;
    }
    .popup2 .popup-container .popup-block .form-popup .buttons{
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
}
@media screen and (max-width: 320px) {

}